import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Select,
  Button,
  DatePicker,
  Form,
  InputNumber,
  Popconfirm,
  Tooltip,
  Table,
  Card
} from "antd";
import { useTranslation } from "react-i18next";
import { noWhitespace } from "../../../utils/rules";
import { notify } from "../../../redux/actions";
import aictia from "../../../const/api";
import moment from "moment";
import { connect } from "react-redux";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { convertColumns } from "../../../utils/columnconverter";
import {
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";
// import ProjectTablee from "../project/table"
const { Option } = Select;


function Project(props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const mainUrl = "Project";

  const [fieldOfUse, setFieldOfUse] = useState([]);
  const [typeOfProject, setTypeOfProject] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoriesType, setCategoriesType] = useState([]);
  const [cutValue, setCutValue] = useState();
  const [subDisable, setSubDisable] = useState(true);
  const [typeDisable, setTypeDisable] = useState(true);
  const [positions, setPositions] = useState([]);
  const [spin, setSpin] = useState(false);
  const [editing, setEditing] = useState(null);
  const [field, setField] = useState([]);
  const [projectCate, setProjectCate] = useState([]);
  const [techArray, setTechArray] = useState([0]);
  const [isEnd, setIsEnd] = useState(false);


  // props
  const { notify } = props;

  const getFieldOfUse = () => {
    aictia.get("UsageArea").then((res) => {
      setFieldOfUse(res.data);
    });
  };
  //
  const getTypeOfProject = () => {
    aictia.get("TypeOfProject").then((res) => {
      setTypeOfProject(res?.data);
    });
  };
  //cate
  const getCategories = () => {
    aictia.get("TechnologyFilter/TechnologyFields").then((res) => {
      setCategories(res.data.technologyCagegory);
    });
  };

  //subcate
  const getSubcategories = (value, index) => {
    let cate = subCategories;

    cate = cate.filter((elem) => elem.index !== index);

    const subCate = categories.find(
      (item) => item.id === value
    ).technologySubCagegory;
    setSubCategories([...cate, { index: index, array: subCate }]);

    setSubDisable(false);
    form.resetFields([["projectCategories", index, "technologySubcategoryId"]]);
    form.resetFields([["projectCategories", index, "technologyTypeId"]]);
  };

  //type
  const getCategoriesType = (value, index) => {
    let cateType = categoriesType;
    cateType = cateType.filter((item) => item.index !== index);
    const data = subCategories.find((item) => item.index === index).array;
    const type = data.find((item) => item.id === value).technologyType;

    setCategoriesType([...cateType, { index: index, array: type }]);

    setTypeDisable(false);
    form.resetFields([["projectCategories", index, "technologyTypeId"]]);
  };

  const setEditingObject = async (i) => {
    setEditing(i);

    await aictia.get(mainUrl + `/${i}`).then((res) => {
      setSubDisable(false);
      setTypeDisable(false);
      setField(res.data.projectUsageAreas);
      setProjectCate(res?.data.projectCategories);

      const techArray1 = [];
      let subCategoriesModify = [];
      let categoriesTypeModify = [];
      if (res.data.projectCategories.length !== 0) {
        res.data.projectCategories.map((item, index) => {
          techArray1.push(index);

          //subCate
          const subCate = categories.find(
            (elem) => elem.id === item.technologyCategoryId
          ).technologySubCagegory;
          subCategoriesModify = [
            ...subCategoriesModify,
            { index: index, array: subCate },
          ];

          //type
          const data = subCategoriesModify.find(
            (elem) => elem.index === index
          ).array;
          const type = data.find(
            (elem) => elem.id === item.technologySubcategoryId
          ).technologyType;
          categoriesTypeModify = [
            ...categoriesTypeModify,
            { index: index, array: type },
          ];
        });
      } else {
        techArray1.push(0);
        setSubDisable(true);
        setTypeDisable(true);
      }
      setSubCategories(subCategoriesModify);
      setCategoriesType(categoriesTypeModify);
      setTechArray(techArray1);

      setTimeout(() => {
        form.setFieldsValue({
          ...res.data,
          startDate: res.data.startDate? moment(res.data.startDate) : null,
          endDate:res.data.endDate ? moment(res.data.endDate) : null,
          usageAreaId: res.data.projectUsageAreas.map(
            (item) => item.usageAreaId
          ),
        });
      }, 1000);
    });
  };

  const getPositions = async () => {
    setSpin(true);
    await aictia.get(`${mainUrl}?companyid=${props.id}`).then((res) => {
      setSpin(false);
      setPositions(
        res.data.map((p, index) => {
          return {
            key: index + 1,
            ...p,
            index: index + 1,
          };
        })
      );
    });
  };

  const deletePosition = async (i) => {
    await aictia
      .delete(`${mainUrl}/${i}`)
      .then(() => {
        // description
        notify("silindi", true);
        getPositions();
      })
      .catch((err) => {
        //error
        notify(err.response, false);
      });
  };

  const cancelEditing = () => {
    setSubDisable(true);
    setTypeDisable(true);
    setEditing(null);
    setTechArray([0]);
    form.resetFields();
  };
  const save = async (values) => {
    console.log("save", values)
    console.log(editing)
    console.log(props);
    const data = {
      ...values,
      reyestrOfCompanyId: props.id,
      projectCategories: values.projectCategories[0].technologyCategoryId ? values.projectCategories : [],
      endDate: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : null,
      projectUsageAreas: values?.usageAreaId ? values?.usageAreaId?.map((item) => {
        return {
          usageAreaId: item,
        };
      }) : []
    };
    if (!editing) {
      await aictia
        .post(mainUrl, data)
        .then((res) => {
          notify("", true);
          getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      console.log("editttt");
      //field
      const dataUsageAreaIds = data?.projectUsageAreas.map(
        (item) => item.usageAreaId
      );
      const updateFieldsIds = field.map((elem) => elem.usageAreaId);
      const updatedField = field.map((item) => {
        const isActive = dataUsageAreaIds.includes(item.usageAreaId);
        return { ...item, isActive };
      });
      data.projectUsageAreas.map((elem) => {
        elem.isActive = true;
      });
      data.projectUsageAreas = data?.projectUsageAreas.filter(
        (elem) => !updateFieldsIds.includes(elem.usageAreaId)
      );
      data.projectUsageAreas.push(...updatedField);
      const modifyData = values?.projectCategories.filter((item) => item !== undefined);
      const projectCategoryIds = modifyData.map((item) => item.id);
      const updatedProjectCate = projectCate.map((item) => {
        const isActive =projectCategoryIds.includes(item.id);
        return { ...item, isActive };
      });
      
      const filteredModifyData = modifyData.filter((elem) => {
        if (elem.technologyCategoryId === undefined) {
          return false; // undefined olanları çıxarırıq
        }
        elem.isActive = true;
        return true; // yalnız uyğun olanları saxlayırıq
      });
      
      filteredModifyData.push(
        ...updatedProjectCate.filter((elem) => elem.isActive === false && elem.technologyCategoryId),

      );
      console.log(modifyData);
      data.projectCategories = filteredModifyData;

      await aictia
        .put(`${mainUrl}/${editing}`, { ...data, id: editing })
        .then((res) => {
          notify("", true);
          getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  useEffect(() => {
    getFieldOfUse();
    getTypeOfProject();
    getCategories();
    getPositions();
  }, []);

  const cols = [
    { key: "index", value: "#", con: true },
    { key: "projectName", value: "Layihənin adı", con: true },
    { key: "ownerName", value: "Layihə sahibinin adı", con: true },
    { key: "typeOfProject", value: "Layihənin növü", con: true },
    { key: "id", value: "", con: false },
  ];
  const columns = [
    {
      title: "#",
      key: "1",
      dataIndex: "index",
      width: 80,
    },
    {
      title: "Layihənin adı",
      key: "2",
      dataIndex: "projectName",
    },
    {
      title: "Layihə sahibinin adı",
      key: "3",
      dataIndex: "ownerName",
    },
    {
      title: "Başlama tarixi ",
      dataIndex: "startDate",
      key: "4",
      render: (i) => {
        return i? moment(i).format("YYYY-MM-DD") : " ";
      },
    },
    // {
    //   title: "Bitmə tarixi ",
    //   dataIndex: "endDate",
    //   key: "5",
    //   render: (i) => {
    //     return i? moment(i).format("YYYY-MM-DD") : " ";
    //   },
    // },
    {
      title: "Layihənin növü",
      dataIndex: "typeOfProject",
      key: "6",
      render: (i) => {
        return <p>{i.name}</p>;
      },
    },
    {
      title: "Büdcə",
      dataIndex: "budget",
      key: "7",
      render: (i) => {
        return <p>{i} azn</p>;
      },
    },
    {
      title: "",
      key: "8",
      dataIndex: "id",
      width: 30,
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePosition(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => setEditingObject(i)}
              >
                <EditFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  console.log(techArray);

  const handleDeleteRow = (element) => {
    console.log(element);

    if (techArray.length > 1) {
      setTechArray(techArray.filter((elem) => elem !== element));
      form.resetFields([
        ["projectCategories", element, "technologyCategoryId"],
      ]);
      form.resetFields([
        [["projectCategories", element, "technologySubcategoryId"]],
      ]);
      form.resetFields([[["projectCategories", element, "technologyTypeId"]]]);
    }
  };

  return (
    <div>
  
        <Form layout="vertical" onFinish={save} form={form}>
          <div className={`commontask taskkk detail mb-30`}>
<Card>

          <Row gutter={[16, 32]}>
            <Col md={6} sm={12} xs={24}>
            <FloatLabel>
                <Form.Item
               
                  name="projectName"
                  validateTrigger="onChange"
                  rules={[noWhitespace(t("inputError"))]}

                >
                  <InputText size={"large"} className="w-full" id="projectName"  />
                </Form.Item>
                <label htmlFor="projectName">{t("Layihənin  adı ")} <span style={{ color: 'red' }}>*</span></label>

                </FloatLabel>
              
            </Col>
            <Col md={6} sm={12} xs={24}>
            <FloatLabel>
                <Form.Item
                  name="ownerName"
                  validateTrigger="onChange"

                >
                  <InputText size={"large"} className="w-full" id="ownerName"  />
                  </Form.Item>
                  <label htmlFor="ownerName">{t("Layihə sahibinin adı")}</label>
              </FloatLabel>
            </Col>
            <Col md={6} sm={12} xs={24}>
              {/* <div className="dh">
                {t("Başlama Tarixi")}
                <div className="flex gap-4">
                  <Switch
                    onChange={(checked) => setIsEnd(checked)}
                    className="ml-10"
                  />
                  <p>Bitmə tarixi</p>
                </div>
              </div> */}
                              <FloatLabel>

              <Form.Item name="startDate" validateTrigger="onChange">
                <DatePicker placeholder={t("Tarixi seçin")} className="w-100" />
              </Form.Item>
              <label htmlFor="startDate">{t("Tarixi seçin")}</label>

              </FloatLabel>
            </Col>
            {/* {isEnd ? (
              <Col md={6} sm={12} xs={24}>
                <Form.Item
                  label={t("*")}
                  name="endDate"
                  validateTrigger="onChange"
                >
                  <DatePicker placeholder={t("Tarixi seçin")} className="w-100" />
                </Form.Item>
              </Col>
            ) : (
              " "
            )} */}

            <Col md={6} sm={12} xs={24}>
            <FloatLabel>


                <Form.Item
                  name="usageAreaId"
                  validateTrigger="onChange"
                //   rules={[whiteSpace(t("inputError"))]}
                >
                  <Select
                  id="usageAreaId"
                    mode="multiple"
                    maxTagCount={1}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    maxTagPlaceholder={(omittedValues) => (
                      <span>+{omittedValues.length}</span>
                    )}
                  >
                    {fieldOfUse.map((p, index) => {
                      return (
                        <Option key={index} value={p.id}>
                          {p.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <label htmlFor="usageAreaId">{t("İstifadə sahəsi")}</label>
            </FloatLabel>

            </Col>

            <Col md={6} sm={12} xs={24}>
            <FloatLabel>


                <Form.Item
                  name="typeOfProjectId"
                  validateTrigger="onChange"
                  rules={[noWhitespace(t("inputError"))]}

                >
                  <Select id="typeOfProjectId">
                    {typeOfProject.map((p, index) => {
                      return (
                        <Option key={index} value={p.id}>
                          {p.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <label htmlFor="typeOfProjectId">{t("Layihənin növü")}<span style={{ color: 'red' }}> *</span></label>
            </FloatLabel>
            </Col>

            <Col md={6} sm={12} xs={24}>
            <FloatLabel>
            <div className="form-lang">

            <Form.Item
                  name="budget"
                  validateTrigger="onChange"
                >
                  <InputNumber id="budget" defaultValue={0} className="w-full"  size={"large"}  />

                </Form.Item>
                <div className="input-lang">azn</div>
                </div>
                <label htmlFor="budget">{t("Büdcə")}</label>
            </FloatLabel>
            </Col>
            <Col md={6} sm={12} xs={24}>
                <FloatLabel>

                <Form.Item
                  name="purpose"
                  validateTrigger="onChange"
                >
                  <InputText size={"large"} className="w-full" id="purpose"  />
                  </Form.Item>
                  <label htmlFor="purpose">{t("Layihənin məqsədi")}</label>
                </FloatLabel>
            </Col>
            <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                <Form.Item
                  name="webPageUrl"
                  validateTrigger="onChange"
                >
                  <InputText size={"large"} className="w-full" id="webPageUrl"  />
                  </Form.Item>
                <label htmlFor="webPageUrl">{t("Web səhifə")}</label>
                </FloatLabel>
            </Col>

            {techArray.map((elem, index) => (
              <Col className="flex  flex-align-center" key={index} style={{paddingLeft:0, paddingRight:0}}>
                <div style={{ display: "none" }}>
                <FloatLabel>

                  <Form.Item
                    name={["projectCategories", elem, "id"]}
                    validateTrigger="onChange"
                  >
                  <InputText size={"large"} className="w-full" id="id"  />
                  </Form.Item>
                  <label htmlFor="id">{t("Texnologiyanın kateqoriyası")}</label>
                  </FloatLabel>
                </div>
                <Col md={6} sm={12} xs={24}>
                  <FloatLabel>
                  <Form.Item
                    name={["projectCategories", elem, "technologyCategoryId"]}
                    validateTrigger="onChange"
                  >
                    <Select onChange={(value) => getSubcategories(value, elem)}>
                      {categories.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>  
                  <label htmlFor="technologyCategoryId">{t("Texnologiyanın kateqoriyası")}</label>
                  </FloatLabel>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <FloatLabel>
                  <Form.Item
                    name={[
                      "projectCategories",
                      elem,
                      "technologySubcategoryId",
                    ]}
                    validateTrigger="onChange"
                  >
                    <Select
                      onChange={(value) => getCategoriesType(value, elem)}
                      disabled={subDisable}
                    >
                      {subCategories?.map((item) => {
                        if (item.index === elem) {
                          return item.array.map((p, index) => {
                            return (
                              <Option key={index} value={p.id}>
                                {p.name}
                              </Option>
                            );
                          });
                        }
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="technologySubcategoryId">{t("Alt kateqoriyası")}</label>
                  </FloatLabel>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <FloatLabel>
                  <Form.Item
                    name={["projectCategories", elem, "technologyTypeId"]}
                    validateTrigger="onChange"
                  >
                    <Select disabled={typeDisable}>
                      {categoriesType.map((item) => {
                        if (item.index === elem) {
                          return item.array.map((p, index) => {
                            return (
                              <Option key={index} value={p.id}>
                                {p.name}
                              </Option>
                            );
                          });
                        }
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="technologyTypeId">{t("Növü")}</label>
                  </FloatLabel>
                </Col>

                <div>
                  <Button
                    onClick={() => setTechArray([...techArray, techArray[techArray.length - 1] + 1])}
                    className={"ml-15"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => {
                      handleDeleteRow(elem);
                    }}
                    className={"ml-15"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<MinusOutlined />}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <div className="flex flex-end mb-20">
            <Button onClick={cancelEditing}>{t("cancel")}</Button>

            <Button type="primary" className="ml-10" htmlType="submit">
              {t("save")}
            </Button>
          </div>
</Card>
          </div>


        </Form>
        <div className="commontask taskkk detail">
          <Card>

      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Table
            loading={spin}
            size="small"
            className="bg-whit animated fadeInLeft mt-15"
            columns={columns}
            dataSource={convertColumns(positions, cols)}
            pagination={{
              pageSize: 10,
              current_page: 1,
              total: positions.length,
            }}
          />
        </Col>
      </Row>
          </Card>
        </div>
    

  </div>
  );
}

export default connect(null, { notify })(Project);

