 //export const siteUrl = "http://api.agros.az/";
// export const siteUrl = "https://api-test-reyestr.aictia.org/api/";

export const siteUrl = "https://api-reyestr.aictia.org/api/";

//export const siteUrl = "http://dev.agros.az/";
export const localUrl = "https://localhost:44362/";

export default siteUrl;

