import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  DatePicker,
  Form,
  InputNumber,
  Switch,
  Card,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { noWhitespace, whiteSpace } from "../../../../utils/rules";
import { notify } from "../../../../redux/actions";
import aictia from "../../../../const/api";
import moment from "moment";
import { connect } from "react-redux";
import JoditEditor from "jodit-react";
import { useLocation } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';

const { Option } = Select;

const Company = (props) => {
  const { t, lang } = useTranslation();
  const [form] = Form.useForm();
  const editor = useRef();
  const [tin, setTin] = useState();

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [typeOfActivity, setTypeOfActivity] = useState([]);
  const [packet, setPacket] = useState([]);
  const [typeOfCompany, setTypeOfCompany] = useState([]);
  const [activity, setActivity] = useState([]);
  const [phoneNum, setPhoneNum] = useState(1);
  const [create, setCreate] = useState(true);
  const [email, setEmail] = useState(1);
  const [ceoPhoneNum, setCeoPhoneNum] = useState(1);
  const [ceoEmail, setCeoEmail] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [disabled, setDisabled] = useState(props.view || props.id ? false : true);
  const [text, setText] = useState({});
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);



  //   const { id } = useParams()
  const items = [
    { item: 1, code: "MMC" },
    { item: 2, code: "QSC" },
    { item: 3, code: "ASC" },
    { item: 4, code: "Fiziki şəxs" },
    { item: 5, code: "İctimai Birlik" },
    { item: 6, code: "Digər" },
    { item: 7, code: "Filial" },
    { item: 8, code: "Nümayəndəlik" },
  ];
  const socialMedia = [
    { title: "Facebook", name: "social1" },
    { title: "Twitter", name: "social2" },
    { title: "Linkedin", name: "social3" },
    { title: "Youtube", name: "social4" },
    { title: "Wikipedia", name: "social5" },
  ];
  const socialMediaCeo = [
    { title: "Facebook", name: "ceosocial1" },
    { title: "Twitter", name: "ceosocial2" },
    { title: "Linkedin", name: "ceosocial3" },
    { title: "Youtube", name: "ceosocial4" },
    { title: "Wikipedia", name: "ceosocial5" },
  ];
  const formRef = useRef();
  // const [fetched, setFetched] = useState(false);
  // const { notify } = props;
  //   console.log(id);
  useEffect(() => {
    if (props.id) {
      const getRegister = () => {
        aictia
          .get(`HistoryOfCompany/Details/${props.id}`)
          .then((res) => {
            setPhoneNum(res.data.phoneOfCompany.length);
            setEmail(res.data.officialMail.length);
            setCeoPhoneNum(res.data.phoneOfCeo.length);
            setCeoEmail(res.data.mailOfCeo.length);
            setText({ note: res.data.note });
            setTin(res.data.tin);
            setStatus(!res.data.activityStatus);
            res.data.socialNetworkofCompany.map((l) => {

              form.setFieldsValue({ [`social${l.type}`]: l.value });
            });
            res.data.socialNetworkofCeo.map((l) => {

              form.setFieldsValue({ [`ceosocial${l.type}`]: l.value });
            });
            form.setFieldsValue({ [`type`]: res.data.phoneOfCompany });

            form.setFieldsValue({
              countryId: res.data.country?.id,
              nameOfCompany: res.data.nameOfCompany,
              typeOfCompanyId: res.data.typeOfCompany?.id,
              dateOfIncorporationCompany: moment(res.data?.dateOfIncorporationCompany),
              officialCEOoftheCompany: res.data?.officialCEOoftheCompany,
              saleSign: res.data.saleSign,
              stateorPrivateId: res.data.stateorPrivate?.id,
              typeOfActivityId: res.data.typeOfActivityCompanyDtos
                .filter((l) => l.isMain)
                .map((l) => {
                  return l.id;
                }),
              typeOfActivitySubId: res.data.typeOfActivityCompanyDtos
                .filter((l) => l.isMain == false)
                .map((l) => {
                  return l.id;
                }),
              activityId: res.data.activityCompanyDtos
                .filter((l) => l.isMain)
                .map((l) => {
                  return l.id;
                }),
              activitySubId: res.data.activityCompanyDtos
                .filter((l) => l.isMain == false)
                .map((l) => {
                  return l.id;
                }),
              officialWebSite: res.data.officialWebSite,
              authorizedCapital: res.data.authorizedCapital,
              recognizedCeo: res.data.recognizedCeo,
              packetOfAictiaId: res?.data?.packetOfAictia?.id,
              legalAddress: res.data.legalAddress,
              actualAddress: res.data.actualAddress,
              workerCount: res.data.workerCount,

              phoneOfCompany: res.data.phoneOfCompany.map((l) => {
                return { value: l.value };
              }),
              officialMail: res.data.officialMail.map((l) => {
                return { value: l.value };
              }),
              phoneOfCeo: res.data.phoneOfCeo.map((l) => {
                return { value: l.value };
              }),
              mailOfCeo: res.data.mailOfCeo.map((l) => {
                return { value: l.value };
              }),
              note: res.data.note,
              dateOfIncorporationCompany: moment(
                res.data.dateOfIncorporationCompany
              ),
            });

            setData(res.data);
          })
          .catch((err) => {
            props.notify("Xəta baş verdi", false);
          });
      };
      getRegister();


      // formRef.current.setFieldsValue(data);
    }
    const getCountry = () => {
      aictia.get("Country").then((res) => {
        setCountry(res.data);
      });
    };
    const getStateOrPrivate = () => {
      aictia.get("StateorPrivate").then((res) => {
        setState(res.data);
      });
    };
    const getTypeOfActivity = () => {
      aictia.get("TypeOfActivity").then((res) => {
        setTypeOfActivity(res.data);
      });
    };
    const getActivity = () => {
      aictia.get("Activity").then((res) => {
        setActivity(res.data);
      });
    };
    const getAictiaPacket = () => {
      aictia.get("PacketOfAictia").then((res) => {
        setPacket(res.data);
      });
    };
    const getTypeOfCompany = () => {
      aictia.get("TypeOfCompany").then((res) => {
        setTypeOfCompany(res.data);
      });
    };
    getStateOrPrivate();
    getCountry();
    getTypeOfActivity();
    getActivity();
    getAictiaPacket();
    getTypeOfCompany();
    //form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.worker, t]);
  const checkTin = () => {
    const tin = form.getFieldValue("tin");
    aictia.get(`ReyestrOfCompany/CheckTIN?TIN=${tin}`).then((res) => {
      setDisabled(true)
      form.setFieldsValue({
        countryId: res.data.country?.id,
        nameOfCompany: res.data.nameOfCompany,
        typeOfCompanyId: res.data.typeOfCompany?.id,
        dateOfIncorporationCompany: moment(res.data?.dateOfIncorporationCompany),
        officialCEOoftheCompany: res.data?.officialCEOoftheCompany,
        saleSign: res.data.saleSign,
        stateorPrivateId: res.data.stateorPrivate?.id,
        typeOfActivityId: res.data.typeOfActivity?.id,
        activityId: res.data.activity?.id,
        officialWebSite: res.data.officialWebSite,
        authorizedCapital: res.data.authorizedCapital,
        recognizedCeo: res.data.recognizedCeo,
        packetOfAictiaId: res?.data?.packetOfAictia?.id,
        phoneOfCompany: res.data.phoneOfCompany.map((l) => {
          return { value: l.value };
        }),
        officialMail: res.data.officialMail.map((l) => {
          return { value: l.value };
        }),
        phoneOfCeo: res.data.phoneOfCeo.map((l) => {
          return { value: l.value };
        }),
        mailOfCeo: res.data.mailOfCeo.map((l) => {
          return { value: l.value };
        }),
        note: res.data.note,
      });

      res.data.socialNetworkofCompany.map((l) => {
        form.setFieldsValue({ [`social${l.type}`]: l.value });
      }
      );
      res.data.socialNetworkofCeo.map((l) => {
        form.setFieldsValue({ [`ceosocial${l.type}`]: l.value });
      });
      setCeoEmail(res.data.mailOfCeo.length);
      setCeoPhoneNum(res.data.phoneOfCeo.length);
      setPhoneNum(res.data.phoneOfCompany.length);
      setEmail(res.data.officialMail.length);
      setText({ note: res.data.note });
      setSearchData(res.data);
      setCreate(false);
    })
      .catch((err) => {
        setDisabled(false)
        setCreate(true);
        form.setFieldsValue({
          countryId: "",
          nameOfCompany: "",
          typeOfCompany: "",
          dateOfIncorporationCompany: "",
          officialCEOoftheCompany: "",
          saleSign: "",
          stateorPrivateId: "",
          typeOfActivityId: "",
          typeOfActivitySubId: "",
          activityId: "",
          activitySubId: "",
          officialWebSite: "",
          authorizedCapital: "",
          recognizedCeo: "",
          packetOfAictiaId: "",
          phoneOfCompany: "",
          officialMail: "",
          phoneOfCeo: "",
          mailOfCeo: "",
          note: "",
        });

        //props.notify("Vöen tapılmadı", false);
        setCeoEmail(1);
        setCeoPhoneNum(1);
        setPhoneNum(1);
        setEmail(1);
        setText({});
        setSearchData([]);
      }
      )

  }
  // useEffect(() => {
  //   if (props.visibleEditWorker && !props.worker) {
  //     form.resetFields();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.visibleEditWorker]);
  const location = useLocation();
  const page = location.state?.page || 1;

  const saveItem = (values) => {
    const media = [
      {
        type: 1,
        socialmeda: "facebook",
      },
      {
        type: 2,
        socialmeda: "twitter",
      },
      {
        type: 3,
        socialmeda: "linkedin",
      },
      {
        type: 4,
        socialmeda: "youtube",
      },
      {
        type: 5,
        socialmeda: "wikipedia",
      },
    ];

    const data = {
      ...values,
      authorizedCapital: String(values.authorizedCapital),
      packetOfAictiaId: Number(values.packetOfAictiaId) ? Number(values.packetOfAictiaId) : null,
      tin: form.getFieldValue("tin"),
      DateOfIncorporationCompany: values.dateOfIncorporationCompany
        ? moment(values.dateOfIncorporationCompany).format("YYYY-MM-DD")
        : null,
      SocialNetworkofCompany: media.map((l) => {
        return {
          type: String(l.type),
          value: values[`social${l.type}`],
        };
      }),
      SocialNetworkofCeo: media.map((l) => {
        return {
          type: String(l.type),
          value: values[`ceosocial${l.type}`],
        };
      }),
      TypeOfCompany: Number(values.typeOfCompany),
    };
    if (props.id) {
      aictia
        .put(`ReyestrOfCompany/${props.id}`, { ...data, id: props.id })
        .then((res) => {
          form.resetFields();

          props.notify("Deyisdirildi ", true);
          window.history.back();
        })
        .catch((err) => {
          props.notify("Xəta baş verdi", false);
        });
    } else {

      aictia
        .post(`ReyestrOfCompany`, data)
        .then((res) => {
          form.resetFields();

          props.notify("Əlavə olundu", true);
          window.history.back();
        })
        .catch((err) => {
          props.notify(err, false);
        });
    }

  };
  const config = {
    readonly: true, // State'e bağlı olarak enable/disable
  };
  const handleInputClick = (name) => {

    form.getFieldValue(name) && window.open(form.getFieldValue(name), "_blank");
  };

  return (
    <div>
      <Form onFinish={saveItem} layout="vertical" form={form}>
        <div className={`commontask taskkk detail `}>
          <Card>
            <Row gutter={[16, 32]}>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="tin"
                    validateTrigger="onChange"
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <InputText disabled={true} readOnly={true} size={"large"} value={tin} className="w-full custom-input-readonly"

                      />
                    </div>
                  </Form.Item>
                  <label htmlFor="tin">{t('tin')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="nameOfCompany"
                    validateTrigger="onChange"
                    rules={[whiteSpace(t("inputError"))]}
                  >
                    <InputText disabled={true} readOnly={true} size={"large"} className="w-full custom-input-readonly" />
                  </Form.Item>
                  <label htmlFor="nameOfCompany">{t('nameOfEntity')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfCompanyId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select disabled={true}>
                      {typeOfCompany.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="typeOfCompanyId">{t('businessEntity')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="dateOfIncorporationCompany"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}

                  >
                    <DatePicker disabled={true} placeholder={t("")} className="w-100" />
                  </Form.Item>
                  <label htmlFor="dateOfIncorporationCompany">{t('registrationDate')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="legalAddress"
                    validateTrigger="onChange"
                  >
                    <InputText disabled={true} readOnly={true} size={"large"} className="w-full custom-input-readonly" />
                  </Form.Item>
                  <label htmlFor="legalAddress">{t('legalAddress')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="actualAddress"
                    validateTrigger="onChange"
                  >
                    <InputText disabled={true} readOnly={true} size={"large"} className="w-full custom-input-readonly" />
                  </Form.Item>
                  <label htmlFor="actualAddress">{t('actualAddress')}</label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>

                  <Form.Item
                    name="workerCount"
                    validateTrigger="onChange"
                  >
                    <InputText disabled={true} readOnly={true} size={"large"} className="w-full" />
                  </Form.Item>
                  <label htmlFor="workerCount">{t('employeeCount')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <div className="form-lang">
                    <Form.Item
                      name="authorizedCapital"
                      validateTrigger="onChange"
                    >
                      <InputNumber
                        readOnly={true} size={"large"} id='authorizedCapital' className="w-full custom-input-readonly" />
                    </Form.Item>
                    <div className="input-lang">azn</div>
                  </div>
                  <label htmlFor="authorizedCapital">{t('charterCapital')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="countryId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={true}>
                      {country.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="countryId">{t('country')}</label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>

                  <Form.Item
                    name="stateorPrivateId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select disabled={true}>
                      {state.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="stateorPrivateId">{t('statePrivate')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="saleSign"
                    validateTrigger="onChange"
                  >
                    <InputText disabled={true} readOnly={true} size={"large"} className="w-full custom-input-readonly" />
                  </Form.Item>
                  <label htmlFor="saleSign">{t('trademark')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="packetOfAictiaId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={true}>
                      {packet.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="packetOfAictiaId">{t('membershipPackage')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfActivityId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select disabled={true}>
                      {typeOfActivity.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="typeOfActivityId">{t('typeOfActivity')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfActivitySubId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={true} id='typeOfActivitySubId' mode="multiple" maxTagCount={1}

                      maxTagPlaceholder={(omittedValues) => (
                        <Tooltip title={omittedValues.map(i => i.label).join(", ")}>

                          <span>+{omittedValues.length}</span>
                        </Tooltip>
                      )}>
                      {typeOfActivity.filter(elem => form.getFieldValue("typeOfActivityId") !== (elem.id))?.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="typeOfActivitySubId">{t('Əlavə fəaliyyət növü')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="activityId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select disabled={true}>
                      {activity.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="activityId">{t('fieldOfActivity')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item

                    name="activitySubId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={true} id='activitySubId' mode="multiple" maxTagCount={1}
                      maxTagPlaceholder={(omittedValues) => (
                        <Tooltip title={omittedValues.map(i => i.label).join(", ")}>

                          <span>+{omittedValues.length}</span>
                        </Tooltip>
                      )}>
                      {activity.filter(elem => elem.id !== form.getFieldValue("activityId")).map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="activitySubId">{t('Əlavə fəaliyyət sahəsi')}</label>
                </FloatLabel>
              </Col>

              {/* <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="officialWebSite"
                    validateTrigger="onChange"
                  >
                    <InputText readOnly={true} size={"large"} className="w-full" />
                  </Form.Item>
                  <label htmlFor="officialWebSite">{t('officialWebsite')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>

                  <Form.Item
                    name="officialCEOoftheCompany"
                    validateTrigger="onChange"
                  >
                    <InputText readOnly={true} disabled={disabled} size={"large"} className="w-full" />
                  </Form.Item>
                  <label htmlFor="officialCEOoftheCompany">{t('officialRepresentative')}</label>
                </FloatLabel>
              </Col> */}
              <Col md={6} sm={12} xs={24}>
                <div className="active status">

                  <Form.Item
                    name="ActivityStatus"
                    validateTrigger="onChange"
                  >
                    Bağlanma statusu
                    <Switch checked={status} disabled={true} />
                  </Form.Item>
                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="recognizedCeoo"
                    validateTrigger="onChange"
                  >
                    <InputText readOnly={true} size={"large"} disabled={true} className="w-full custom-input-readonly" />
                  </Form.Item>
                  <label htmlFor="recognizedCeoo">{t('Təqdimatın adı')}</label>
                </FloatLabel>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row gutter={[16, 16]}>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('Şirkət profili')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialWebSite"
                        validateTrigger="onChange"
                      >
                        <InputText disabled={true} readOnly={true} size={"large"} id='officialWebsite' className="w-full custom-input-readonly" />
                      </Form.Item>
                      <label htmlFor="officialWebsite">{t('officialWebsite')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(email)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["officialMail", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText disabled={true} readOnly={true} size={"large"} id='officialEmail' className="w-full custom-input-readonly" />


                            </Form.Item>
                            <label htmlFor="officialEmail">{t('officialEmail')}</label>
                          </FloatLabel>
                        </div>
                      </div>
                    ))}

                  </div>

                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(phoneNum)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["phoneOfCompany", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText disabled={true} readOnly={true} size={"large"} id='PhoneOfCompany' className="w-full custom-input-readonly" />


                            </Form.Item>
                            <label htmlFor="PhoneOfCompany">{t('companyPhone')}</label>
                          </FloatLabel>
                        </div>
                      </div>
                    ))}

                  </div>
                  {socialMedia.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={elem.name}
                        >
                          <InputText disabled={true} readOnly={true} id={elem.title} className="w-full custom-input-readonly" />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}
                  {/* {[...Array(ceoPhoneNum)].map((_, index) => (
                    <div className="mb-10 " key={index}>
                      <Form.Item
                        className={"mb-0 w-100 mr-10"}
                        validateTrigger="onChange"
                        name={["phoneOfCeo", index, "value"]}
                        rules={[
                          {
                            pattern: /^[^a-zA-Z]*$/,
                            message: "Hərflər olmamalıdır!",
                          }
                        ]}
                      >
                        <Input readOnly={true} placeholder={"+994XXXXXXXXXX"} />
                      </Form.Item>
                    </div>
                  ))} */}
                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('officialRepresentative')}</span>
                  </div>
                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialCEOoftheCompany"
                        validateTrigger="onChange"
                      >

                        <InputText disabled={true} readOnly={true} size={"large"} id='officialCEOoftheCompany' className="w-full custom-input-readonly" />


                      </Form.Item>
                      <label htmlFor="officialCEOoftheCompany">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(ceoEmail)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              className={"mb-0 w-100 mr-10"}
                              validateTrigger="onChange"
                              name={["mailOfCeo", index, "value"]}
                            >
                              <InputText disabled={true} readOnly={true} size={"large"} id='mailOfCeo' className="w-full custom-input-readonly" />
                            </Form.Item>
                            <label htmlFor="mailOfCeo">{t('ceoEmail')}</label>
                          </FloatLabel>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(ceoPhoneNum)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["phoneOfCeo", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText disabled={true} readOnly={true} size={"large"} id='phoneOfCeo' className="w-full custom-input-readonly" />


                            </Form.Item>
                            <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                          </FloatLabel>
                        </div>
                      </div>
                    ))}
                    {/* <FloatLabel>
                      <Form.Item
                        name="phoneOfCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={disabled} size={"large"} id='phoneOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                    </FloatLabel> */}
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={elem.name}
                        >
                          <InputText disabled={true} readOnly={true} id={elem.title} className="w-full custom-input-readonly" />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>


              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('recognizedLeader')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="recognizedCeo"
                        validateTrigger="onChange"
                      >

                        <InputText disabled={true} readOnly={true} className="w-full" size={"large"} id='recognizedCeo' />

                      </Form.Item>
                      <label htmlFor="recognizedCeo">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="mailOrrfCeo"
                        validateTrigger="onChange"
                      >

                        <InputText disabled={true} readOnly={true} size={"large"} id='mailOfCeo' className="w-full custom-input-readonly" />


                      </Form.Item>
                      <label htmlFor="mailOfCeo">{t('Şəxsin maili')}</label>
                    </FloatLabel>
                  </div>
                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="phoneOrrfCeo"
                        validateTrigger="onChange"
                      >

                        <InputText disabled={true} readOnly={true} size={"large"} id='phoneOfCeo' className="w-full custom-input-readonly" />


                      </Form.Item>
                      <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                    </FloatLabel>
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={'fjhhfhf'}
                        >
                          <InputText disabled={true} readOnly={true} id={elem.title} className="w-full custom-input-readonly" />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('Əlaqəli şəxs')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialCEOoftheCompffany"
                        validateTrigger="onChange"
                      >

                        <InputText disabled={true} readOnly={true} size={"large"} id='officialCEOoftheCompany' className="w-full custom-input-readonly" />


                      </Form.Item>
                      <label htmlFor="officialCEOoftheCompany">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="mailOfffCeo"
                        validateTrigger="onChange"
                      >

                        <InputText disabled={true} readOnly={true} size={"large"} id='mailOfCeo' className="w-full custom-input-readonly" />


                      </Form.Item>
                      <label htmlFor="mailOfCeo">{t('Şəxsin maili')}</label>
                    </FloatLabel>
                  </div>
                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="phoneOfffCeo"
                        validateTrigger="onChange"
                      >

                        <InputText disabled={true} readOnly={true} size={"large"} id='phoneOfCeo' className="w-full custom-input-readonly" />


                      </Form.Item>
                      <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                    </FloatLabel>
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={'djdjd'}
                        >
                          <InputText disabled={true} readOnly={true} id={elem.title} className="w-full custom-input-readonly" />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>



              {/* <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('ceoEmail')}</span>
                  </div>
                  {[...Array(ceoEmail)].map((_, index) => (
                    <div className="mb-10 " key={index}>
                      <Form.Item
                        className={"mb-0 w-100 mr-10"}
                        validateTrigger="onChange"
                        name={["mailOfCeo", index, "value"]}
                      >
                        <Input readOnly={true} />
                      </Form.Item>
                    </div>
                  ))}

                </div>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('companyPhone')}</span>
                  </div>
                  {[...Array(phoneNum)].map((_, index) => (
                    <div className="mb-10 " key={index}>
                      <Form.Item
                        className={"mb-0 w-100 mr-10"}
                        validateTrigger="onChange"
                        name={["phoneOfCompany", index, "value"]}
                        rules={[
                          {
                            pattern: /^[^a-zA-Z]*$/,
                            message: "Hərflər olmamalıdır!",
                          }
                        ]}
                      >
                        <Input readOnly={true} placeholder={"+994XXXXXXXXXX"} />
                      </Form.Item>
                    </div>
                  ))}

                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('officialEmail')}</span>
                  </div>
                  {[...Array(email)].map((_, index) => (
                    <div className="mb-10 " key={index}>
                      <Form.Item
                        className={"mb-0 w-100 mr-10"}
                        validateTrigger="onChange"
                        name={["officialMail", index, "value"]}
                      >
                        <Input readOnly={true} />
                      </Form.Item>
                    </div>
                  ))}

                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('companySocialMedia')}</span>
                  </div>
                  {socialMedia.map((elem, index) => (
                    <div className="mb-10 " key={index}>
                      <Form.Item
                        className={"mb-0 w-100 mr-10"}
                        validateTrigger="onChange"
                        name={elem.name}
                        label={elem.title}
                      >
                        <Input readOnly={true} onClick={() => props.view ? handleInputClick(elem.name) : null} />
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('directorSocialMedia')}</span>
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-10 " key={index}>
                      <Form.Item
                        className={"mb-0 w-100 mr-10"}
                        validateTrigger="onChange"
                        name={elem.name}
                        label={elem.title}
                      >
                        <Input readOnly={true} onClick={() => props.view ? handleInputClick(elem.name) : null} />
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </Col>*/
              }
            </Row>
          </Card>
        </div>
        <Col sm={12} xs={24}>
          <div className="custom-select-wrapper ">
            <Form.Item
              label={t("note")}

              name="note"
              validateTrigger="onChange"
            >
              <JoditEditor
                onChange={(newContent) => {
                  form.setFieldsValue({ note: newContent });
                }}
                className={"mb-15"}
                config={config}
                ref={editor}
                value={text["note"]}
                tabIndex={1}
              />

            </Form.Item>
          </div>
        </Col>

      </Form>
    </div>
  );
};

export default connect(null, { notify })(Company);

