import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Form,
  Switch,
  Card,
  InputNumber,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { noWhitespace } from "../../../utils/rules";
import { notify } from "../../../redux/actions";
import aictia from "../../../const/api";
import moment from "moment";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import JoditEditor from "jodit-react";
import { useLocation } from "react-router-dom";
import "primereact/resources/themes/lara-light-cyan/theme.css";
const { Option } = Select;


const Company = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const editor = useRef();
  const [tin, setTin] = useState();

  const [date, setDate] = useState();
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [typeOfActivity, setTypeOfActivity] = useState([]);
  const [packet, setPacket] = useState([]);
  const [typeOfCompany, setTypeOfCompany] = useState([]);
  const [activity, setActivity] = useState([]);
  const [phoneNum, setPhoneNum] = useState(1);
  const [create, setCreate] = useState(true);
  const [email, setEmail] = useState(1);
  const [ceoPhoneNum, setCeoPhoneNum] = useState(1);
  const [ceoEmail, setCeoEmail] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [value, setValue] = useState();
  const [disabled, setDisabled] = useState(props.view || props.id ? false : true);
  const [text, setText] = useState({});
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const [countryData, setCountryData] = useState();
  const [disabledTin, setDisabledTin] = useState(true);

  const items = [
    { item: 1, code: "MMC" },
    { item: 2, code: "QSC" },
    { item: 3, code: "ASC" },
    { item: 4, code: "Fiziki şəxs" },
    { item: 5, code: "İctimai Birlik" },
    { item: 6, code: "Digər" },
    { item: 7, code: "Filial" },
    { item: 8, code: "Nümayəndəlik" },
  ];
  const socialMedia = [
    { title: "Facebook", name: "social1" },
    { title: "Twitter", name: "social2" },
    { title: "Linkedin", name: "social3" },
    { title: "Youtube", name: "social4" },
    { title: "Wikipedia", name: "social5" },
  ];
  const socialMediaCeo = [
    { title: "Facebook", name: "ceosocial1" },
    { title: "Twitter", name: "ceosocial2" },
    { title: "Linkedin", name: "ceosocial3" },
    { title: "Youtube", name: "ceosocial4" },
    { title: "Wikipedia", name: "ceosocial5" },
  ];

  useEffect(() => {
    if (props.id) {
      const getRegister = () => {
        aictia
          .get(`ReyestrOfCompany/details/${props.id}`)
          .then((res) => {
            setPhoneNum(res?.data?.phoneOfCompany?.length);
            setEmail(res.data.officialMail?.length);
            setCeoPhoneNum(res.data.phoneOfCeo?.length);
            setCeoEmail(res.data.mailOfCeo?.length);
            setText({ note: res.data.note });
            setTin(res.data.tin);
            setStatus(!res.data.activityStatus);
            form.setFieldsValue({
              ...res.data,
              typeOfCompanyId: res.data.typeOfCompany?.name,
              countryId: res.data.country?.name,
              stateorPrivateId: res.data.stateorPrivate?.name,
              packetOfAictiaId: res?.data?.packetOfAictia?.name,
              ActivityStatus: !res.data.activityStatus,
              ClosureDate: res.data.closureDate ? moment(res.data.closureDate) : null,
              dateOfIncorporationCompany: moment(
                res.data.dateOfIncorporationCompany
              ),
              typeOfActivityId: res.data.typeOfActivityCompanyDtos?.filter((l) => l.isMain)
                .map((l) => {
                  return l.name;
                }),
              typeOfActivitySubId: res.data.typeOfActivityCompanyDtos?.filter((l) => l.isMain == false)
                .map((l) => {
                  return l.name;
                }),
              activityId: res.data.activityCompanyDtos?.filter((l) => l.isMain)
                .map((l) => {
                  return l.name;
                }),
              activitySubId: res.data.activityCompanyDtos?.filter((l) => l.isMain == false)
                .map((l) => {
                  return l.name;
                }),
            });

            res.data.socialNetworkofCompany.map((l) => {

              form.setFieldsValue({ [`social${l.type}`]: l.value });
            });
            res.data.socialNetworkofCeo.map((l) => {

              form.setFieldsValue({ [`ceosocial${l.type}`]: l.value });
            });
            form.setFieldsValue({ [`type`]: res.data.phoneOfCompany });



          })
          .catch((err) => {
            props.notify("Xəta baş verdi", false);
          });
      };
      getRegister();


    }

  }, [props.worker, t]);

  const config = {
    readonly: true, // State'e bağlı olaraq enable/disable
  };



  return (
    <div>
      <Form layout="vertical" form={form}>
        <div className={`commontask taskkk detail`}>
          <Card>
            <Row gutter={[16, 32]}>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="tin"
                    validateTrigger="onChange"
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <InputText disabled={true} size={"large"} className='w-full ' value={tin} id="tin" maxLength={10} />

                    </div>

                  </Form.Item>
                  <label htmlFor="tin">{t('tin')} <span style={{ color: 'red' }}>*</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="nameOfCompany"
                    validateTrigger="onChange"
                  >


                    <InputText readOnly={true} disabled={true} size={"large"} id='nameOfCompany' className="w-full" />

                  </Form.Item>
                  <label htmlFor="nameOfCompany">{t('nameOfEntity')} <span style={{ color: 'red' }}>*</span></label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfCompanyId"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={true} disabled={true} size={"large"} id='typeOfCompanyId' className="w-full" >
                    </InputText>


                  </Form.Item>
                  <label htmlFor="typeOfCompanyId">{t('businessEntity')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="dateOfIncorporationCompany"
                    validateTrigger="onChange"
                  >

                    <DatePicker
                      placeholder=""
                      disabled={true}
                      className="w-100"
                      id="dateOfIncorporationCompany"
                    />
                  </Form.Item>
                  <label htmlFor="dateOfIncorporationCompany">{t('registrationDate')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="legalAddress"
                    validateTrigger="onChange"
                  >
                    <InputText readOnly={true} disabled={true} size={"large"} id='legalAddress' className="w-full" />
                  </Form.Item>
                  <label htmlFor="legalAddress">{t('legalAddress')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="actualAddress"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={true} disabled={true} size={"large"} id='actualAddress' className="w-full" />


                  </Form.Item>
                  <label htmlFor="actualAddress">{t('actualAddress')}</label>
                </FloatLabel>
              </Col>


              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="workerCount"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={true} disabled={true} size={"large"} id='workerCount' className="w-full" />


                  </Form.Item>
                  <label htmlFor="workerCount">{t('employeeCount')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="authorizedCapital"
                    validateTrigger="onChange"
                  >
                    <InputNumber
                      readOnly={true} size={"large"} id='authorizedCapital' className="w-full custom-input-readonly" />


                  </Form.Item>
                  <label htmlFor="authorizedCapital">{t('charterCapital')}</label>

                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="countryId"
                    validateTrigger="onChange"
                  >
                    <InputText readOnly={true} disabled={true} size={"large"} id='country' className="w-full" />
                  </Form.Item>
                  <label htmlFor="country">{t('country')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="stateorPrivateId"
                    validateTrigger="onChange"
                  >
                    <InputText readOnly={true} disabled={true} size={"large"} id='statePrivate' className="w-full" />

                  </Form.Item>
                  <label htmlFor="stateorPrivate">{t('statePrivate')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="saleSign"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={true} disabled={true} size={"large"} id='saleSign' className="w-full" />


                  </Form.Item>
                  <label htmlFor="saleSign">{t('trademark')}</label>
                </FloatLabel>
              </Col>


              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="packetOfAictiaId"
                    validateTrigger="onChange"
                  >
                    <InputText readOnly={true} disabled={true} className="w-full" size={"large"} id='membershipPackage' />

                  </Form.Item>
                  <label htmlFor="membershipPackage">{t('membershipPackage')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfActivityId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={true} id='typeOfActivity'>
                      {typeOfActivity.filter(elem => !form.getFieldValue("typeOfActivitySubId")?.includes(elem.id)).map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="typeOfActivity">{t('typeOfActivity')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfActivitySubId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={true} id='typeOfActivitySubId' mode="multiple" maxTagCount={1}
                      maxTagPlaceholder={(omittedValues) => (
                        <Tooltip title={omittedValues.map(i => i.label).join(", ")}>
                          <span>+{omittedValues.length}</span>
                        </Tooltip>
                      )}>
                      {typeOfActivity.filter(elem => form.getFieldValue("typeOfActivityId") !== (elem.id))?.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="typeOfActivitySubId">{t('Əlavə fəaliyyət növü')}</label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="activityId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select disabled={true} id='fieldOfActivity'>
                      {activity.filter(elem => !form.getFieldValue("activitySubId")?.includes(elem.id)).map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="fieldOfActivity">{t('fieldOfActivity')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="activitySubId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={true} id='activitySubId' mode="multiple" maxTagCount={1}
                      maxTagPlaceholder={(omittedValues) => (
                        <Tooltip title={omittedValues.map(i => i.label).join(", ")}>
                          <span>+{omittedValues.length}</span>
                        </Tooltip>)}>
                      {activity.filter(elem => elem.id !== form.getFieldValue("activityId")).map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="activitySubId">{t('Əlavə fəaliyyət sahəsi')}</label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <div className="status">
                  <Form.Item
                    name="ActivityStatus"
                    validateTrigger="onChange"
                  >
                    Bağlanma statusu

                    <Switch checked={status} disabled={true} placeholder={t('Bağlanma statusu')} />
                  </Form.Item>
                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="recognizedCejjo"
                    validateTrigger="onChange"
                  >
                    <InputText readOnly={true} disabled={true} className="w-full" size={"large"} id='recognizedCeo' />

                  </Form.Item>
                  <label htmlFor="recognizedCeo">{t('Təqdimatın adı')}</label>
                </FloatLabel>
              </Col>

            </Row>
          </Card>
          <Card>
            <Row gutter={[16, 16]}>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('Şirkət profili')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialWebSite"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={true} disabled={true} size={"large"} id='officialWebsite' className="w-full" />


                      </Form.Item>
                      <label htmlFor="officialWebsite">{t('officialWebsite')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(email)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["officialMail", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText readOnly={true} disabled={true} size={"large"} id='officialEmail' className="w-full" />


                            </Form.Item>
                            <label htmlFor="officialEmail">{t('officialEmail')}</label>
                          </FloatLabel>
                        </div>
                      </div>
                    ))}

                  </div>
                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(phoneNum)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["phoneOfCompany", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText readOnly={true} disabled={true} size={"large"} id='PhoneOfCompany' className="w-full" />


                            </Form.Item>
                            <label htmlFor="PhoneOfCompany">{t('companyPhone')}</label>
                          </FloatLabel>
                        </div>
                      </div>
                    ))}

                  </div>
                  {socialMedia.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={elem.name}
                        >
                          <InputText readOnly={true} id={elem.title} disabled={true} className="w-full" />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('officialRepresentative')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialCEOoftheCompany"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={true} disabled={true} size={"large"} id='officialCEOoftheCompany' className="w-full" />


                      </Form.Item>
                      <label htmlFor="officialCEOoftheCompany">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(ceoEmail)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              className={"mb-0 w-100 mr-10"}
                              validateTrigger="onChange"
                              name={["mailOfCeo", index, "value"]}
                            >
                              <InputText readOnly={true} disabled={true} size={"large"} id='mailOfCeo' className="w-full" />
                            </Form.Item>
                            <label htmlFor="mailOfCeo">{t('ceoEmail')}</label>
                          </FloatLabel>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(ceoPhoneNum)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["phoneOfCeo", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText readOnly={true} disabled={true} size={"large"} id='phoneOfCeo' className="w-full" />


                            </Form.Item>
                            <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                          </FloatLabel>
                        </div>
                      </div>
                    ))}
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={elem.name}
                        >
                          <InputText readOnly={true} disabled={true} id={elem.title} className="w-full" />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>


              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('recognizedLeader')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="recognizedCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={true} disabled={true} className="w-full" size={"large"} id='recognizedCeo' />

                      </Form.Item>
                      <label htmlFor="recognizedCeo">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="mailOrrfCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={true} disabled={true} size={"large"} id='mailOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="mailOfCeo">{t('Şəxsin maili')}</label>
                    </FloatLabel>
                  </div>
                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="phoneOrrfCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={true} disabled={true} size={"large"} id='phoneOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                    </FloatLabel>
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={'fjhhfhf'}
                        >
                          <InputText readOnly={true} disabled={true} id={elem.title} className="w-full" />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('Əlaqəli şəxs')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialCEOoftheCompffany"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={true} disabled={true} size={"large"} id='officialCEOoftheCompany' className="w-full" />


                      </Form.Item>
                      <label htmlFor="officialCEOoftheCompany">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="mailOfffCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={true} disabled={true} size={"large"} id='mailOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="mailOfCeo">{t('Şəxsin maili')}</label>
                    </FloatLabel>
                  </div>
                  <div className="mb-30 ">
                    <FloatLabel>
                      <Form.Item
                        name="phoneOfffCeo"
                        validateTrigger="onChange"
                      >
                        <InputText readOnly={true} disabled={true} size={"large"} id='phoneOfCeo' className="w-full" />
                      </Form.Item>
                      <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                    </FloatLabel>
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={'djdjd'}
                        >
                          <InputText readOnly={true} disabled={true} id={elem.title} className="w-full" />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>

            </Row>
          </Card>
        </div>
        <Col sm={12} xs={24}>
          <div className="custom-select-wrapper ">
            <Form.Item
              name="note"
              validateTrigger="onChange"
            >
              <JoditEditor
                onChange={(newContent) => {
                  form.setFieldsValue({ note: newContent });
                }}
                className={"mb-15"}
                config={config}
                ref={editor}
                value={text["note"]}
                tabIndex={1}
                disabled={true}
              />

            </Form.Item>
          </div>
        </Col>
      </Form>
    </div>
  );
};

export default connect(null, { notify })(Company);
