import React, { useEffect, useState, useRef } from "react";
import {

  Card,
  Button,
  Form,
  Input,

  Spin
} from "antd";

import { notify } from "../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace, noWhitespace } from "../../utils/rules";
import { LoadingOutlined } from '@ant-design/icons';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
function Technology(props) {
  const mainUrl = "Technology";
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const nameInput = useRef();

  const setEditingObject = async (i) => {

    await aictia.get(mainUrl + `/${props.id}`).then((res) => {
      const name = res.data.name
      let obj = {};
      obj[`name`] = name;
      form.setFieldsValue(obj);
    });
  };

  const cancelEditing = () => {
    props.setEditing(null);
    form.resetFields();
  };


  const savePosition = async (values) => {
    setLoading(true);
    if (!props.id) {
      await aictia
        .post(mainUrl, values)
        .then((res) => {
          notify("", true);
          setLoading(false);
          props.getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      await aictia
        .put(`${mainUrl}/${props.id}`, { id: props.id, name: values.name })
        .then((res) => {
          notify("", true);
          setLoading(false);
          props.getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };
  useEffect(() => {
    setEditingObject()
  }, [props.id])
  return (

    <Card title={t("addTo")} className={"animated fadeInRight administrator"}>
      <Form layout="vertical" onFinish={savePosition} form={form}>
        <div className="form-lang mb-20">
          <FloatLabel>
            <Form.Item
              className="mb-5"
              validateTrigger="onChange"
              name="name"
              rules={[whiteSpace(t("inputError"))]}
            >
            <InputText ref={nameInput} className="w-full" id="name" />
            </Form.Item>
          <label htmlFor="name">{t('Texnologiyaların kateqoriyasının adı')}</label>
          </FloatLabel>

        </div>
        <div className="flex  flex-between mt-15">
          <Button onClick={cancelEditing}>{t("cancel")}</Button>
          <Button htmlType="submit">{loading ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : t("save")}</Button>
        </div>
      </Form>
    </Card>

  )
}

export default Technology
