import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Tooltip,
  Input,
  Popconfirm,
  Upload,
  Select,
} from "antd";
import {
  UnorderedListOutlined,
  EditFilled,
  DeleteFilled,
  ToolFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace, noWhitespace } from "../../../../utils/rules";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
const Types = (props) => {

  const [form] = Form.useForm();
  const { t } = useTranslation();


  const [positions, setPositions] = useState([]);
  const [spin, setSpin] = useState(false);
  const [editing, setEditing] = useState(null);
  const [reset, setReset] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState("TypeOfProject");
  const cols = [
    { key: "index", value: "#", con: true },
    { key: "name", value: t("name"), con: true },
    { key: "id", value: "", con: false },
  ];
  const nameInput = useRef();
  const { notify } = props;



  const { Option } = Select;
  const columns = [
    {
      title: "#",
      key: "1",
      dataIndex: "index",
      width: 80,
    },
    {
      title: t("name"),
      key: "2",
      dataIndex: "name",
    },
    {
      title: "",
      key: "3",
      dataIndex: "id",
      width: 30,
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePosition(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => setEditingObject(i)}
              >
                <EditFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const setEditingObject = async (i) => {
    setEditing(i);
    await aictia.get(selectedCategory + `/${i}`).then((res) => {
      const name = res.data.name
      let obj = {};
      obj[`name`] = name;
      form.setFieldsValue(obj);
    });
  };

  const cancelEditing = () => {
    setEditing(null);
    form.resetFields();
  };

  const deletePosition = async (i) => {
    await aictia
      .delete(`${selectedCategory}/${i}`)
      .then(() => {
        // description
        notify("silindi", true);
        getPositions();
      })
      .catch((err) => {
        //error
        notify(err.response, false);
      });
  };

  const savePosition = async (values) => {
    if (!editing) {
      await aictia
        .post(selectedCategory, values)
        .then((res) => {
          notify("", true);
          getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      await aictia
        .put(`${selectedCategory}/${editing}`, { id: editing, name: values.name })
        .then((res) => {
          notify("", true);
          getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  const getPositions = async () => {
    setSpin(true);
    await aictia.get(selectedCategory).then((res) => {
      setSpin(false);
      setPositions(
        res.data.map((p, index) => {
          return {
            key: index + 1,
            ...p,
            index: index + 1,
          };
        })
      );
    });
  };
  useEffect(() => {
    getPositions();
  }, [selectedCategory]);

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24}>
        <div className="border animated fadeInDown p-2 mt-0 bg-white">
          <UnorderedListOutlined className="f-20 mr-15" />
          <span className="f-20 bold">Növlər</span>
        </div>
      </Col>

      <Col xs={12}>
        <div className="form-lang">
          <Form.Item
            name={"Technologies"}
            validateTrigger="onChange"
            rules={[noWhitespace(t("inputError"))]}
          >
            <Select
              defaultValue="Layihə"
              onChange={(e) => setSelectedCategory(e)}
            >
              <Option key={1} value={"TypeOfProject"}>
                Layihə
              </Option>
              <Option key={2} value={"TypeOfStartup"}>
                Startup
              </Option>
              <Option key={3} value={"TypeOfInnovation"}>
                İnnovasiya
              </Option>
              <Option key={4} value={"TypeOfInvention"}>
                İxtira
              </Option>
              <Option key={5} value={"TypeOfProduct"}>
                Məhsul
              </Option>
            </Select>
          </Form.Item>
        </div>
        <Col lg={24} xs={24}>
          <Table
            loading={spin}
            size="small"
            className="bg-white animated fadeInLeft"
            columns={columns}
            dataSource={convertColumns(positions, cols)}
            pagination={{
              pageSize: 10,
              current_page: 1,
              total: positions.length,
            }}
          />
        </Col>
      </Col>

      <Col lg={12} xs={24}>
        <Card title={"Əlavə et"} className={"animated fadeInRight administrator"}>
          <Form layout="vertical" onFinish={savePosition} form={form}>
            <div className="form-lang mb-20">
              <FloatLabel>
                <Form.Item
                  className="mb-5"
                  validateTrigger="onChange"
                  name="name"
                  rules={[whiteSpace(t("inputError"))]}
                >
                  <InputText ref={nameInput} className="w-full" id="name" />

                </Form.Item>
                <label htmlFor="name">{t(selectedCategory)}</label>
              </FloatLabel>
            </div>
            <div className="flex  flex-between mt-15">
              <Button onClick={cancelEditing}>{t("cancel")}</Button>
              <Button htmlType="submit">{t("save")}</Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

export default connect(mapStateToProps, { notify })(Types);
