import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  DatePicker,
  Form,
  InputNumber,
  Tabs,
} from "antd";
import { useTranslation } from "react-i18next";
import { noWhitespace, whiteSpace } from "../../../../utils/rules";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { UsergroupAddOutlined } from "@ant-design/icons";
import Company from './Company'
import { useLocation, useHistory } from "react-router-dom";
const { Option } = Select;

const DetailHistory = (props) => {
  const { t } = useTranslation();

  const [text, setText] = useState({});
  const { id } = useParams();

  const formRef = useRef();
  const { TabPane } = Tabs;
  const location = useLocation();
  const page = location.state?.page || 1;
  const view = window.location.hash.includes("view") ? true : false;
 
  
  const numbers = location.pathname.match(/\d+/g);

 
  

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UsergroupAddOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">
              {t('companyInfo')}
              </span>
            </div>
            <Link
                to={{
                  pathname: `/register/history/${numbers[0]}`,
                  state: { locales: null },
                }}
            >
              <Button type={"primary"}>X</Button>
            </Link>
          </div>
        </Col>
        <Col xs={24}>
          <Tabs>
            <TabPane tab={t('company')} key={"1"}>
              <div className={`p-2 animated edit fadeInUp bg-white`}>
                <Company id={numbers[1]} page={page}  />
              </div>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, { notify })(DetailHistory);
