import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Form,
  Switch,
  Button,
  Card,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { noWhitespace, whiteSpace } from "../../../utils/rules";
import { notify } from "../../../redux/actions";
import aictia from "../../../const/api";
import moment from "moment";
import { connect } from "react-redux";
import { PlusOutlined, MinusOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useLocation } from "react-router-dom";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const { Option } = Select;

const Company = (props) => {
  const { t, lang } = useTranslation();
  const [form] = Form.useForm();
  const editor = useRef();
  const [tin, setTin] = useState();

  const [date, setDate] = useState();
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [typeOfActivity, setTypeOfActivity] = useState([]);
  const [packet, setPacket] = useState([]);
  const [typeOfCompany, setTypeOfCompany] = useState([]);
  const [activity, setActivity] = useState([]);
  const [phoneNum, setPhoneNum] = useState(1);
  const [create, setCreate] = useState(true);
  const [email, setEmail] = useState(1);
  const [ceoPhoneNum, setCeoPhoneNum] = useState(1);
  const [ceoEmail, setCeoEmail] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [value, setValue] = useState();
  const [disabled, setDisabled] = useState(props.view || props.id ? false : true);
  const [text, setText] = useState({});
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const [countryData, setCountryData] = useState();
  const [disabledTin, setDisabledTin] = useState(true);
  const [loading, setLoading] = useState(false);
  const items = [
    { item: 1, code: "MMC" },
    { item: 2, code: "QSC" },
    { item: 3, code: "ASC" },
    { item: 4, code: "Fiziki şəxs" },
    { item: 5, code: "İctimai Birlik" },
    { item: 6, code: "Digər" },
    { item: 7, code: "Filial" },
    { item: 8, code: "Nümayəndəlik" },
  ];
  const socialMedia = [
    { title: "Facebook", name: "social1" },
    { title: "Twitter", name: "social2" },
    { title: "Linkedin", name: "social3" },
    { title: "Youtube", name: "social4" },
    { title: "Wikipedia", name: "social5" },
  ];
  const socialMediaCeo = [
    { title: "Facebook", name: "ceosocial1" },
    { title: "Twitter", name: "ceosocial2" },
    { title: "Linkedin", name: "ceosocial3" },
    { title: "Youtube", name: "ceosocial4" },
    { title: "Wikipedia", name: "ceosocial5" },
  ];
  const formRef = useRef();
  const [isFocused, setIsFocused] = useState({});

  const handleFocus = (name) => {
    setIsFocused((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (name, value) => {
    if (name === "authorizedCapital") {
      if (inputValues[name] === undefined || inputValues[name] === null || inputValues[name] === '') {
        setIsFocused((prev) => ({ ...prev, [name]: false }));
      }
    } else {
      if (!value) {
        setIsFocused((prev) => ({ ...prev, [name]: false }));
      }
    }
  };

  useEffect(() => {
    if (props.id) {
      const getRegister = () => {
        aictia
          .get(`ReyestrOfCompany/${props.id}`)
          .then((res) => {
            setPhoneNum(res?.data?.phoneOfCompany?.length);
            setEmail(res.data.officialMail?.length);
            setCeoPhoneNum(res.data.phoneOfCeo?.length);
            setCeoEmail(res.data.mailOfCeo?.length);
            setText({ note: res.data.note });
            if (res.data.countryId) {
              setIsFocused((prev) => ({ ...prev, countryId: true }));
            }
            if (res.data.authorizedCapital !== null || res.data.authorizedCapital !== undefined) {
              setIsFocused((prev) => ({ ...prev, authorizedCapital: true }));
            }
            if (res.data.typeOfCompanyId) {
              setIsFocused((prev) => ({ ...prev, typeOfCompanyId: true }));
            }
            if (res.data.stateorPrivateId) {
              setIsFocused((prev) => ({ ...prev, stateorPrivateId: true }));
            }
            if (res.data.packetOfAictiaId) {
              setIsFocused((prev) => ({ ...prev, packetOfAictiaId: true }));
            }
            if (res.data.typeOfActivityId) {
              setIsFocused((prev) => ({ ...prev, typeOfActivityId: true }));
            }
            if (res.data.activityId) {
              setIsFocused((prev) => ({ ...prev, activityId: true }));
            }
            if (res.data.activitySubId) {
              setIsFocused((prev) => ({ ...prev, activitySubId: true }));
            }
            if (res.data.typeOfActivitySubId) {
              setIsFocused((prev) => ({ ...prev, typeOfActivitySubId: true }));
            }
            if (res.data.dateOfIncorporationCompany) {
              setIsFocused((prev) => ({ ...prev, dateOfIncorporationCompany: true }));
            }
            setTin(res.data.tin);
            setStatus(!res.data.activityStatus);
            form.setFieldsValue({
              ...res.data,
              // mailOfCeo: res.data.mailOfCeo?.[0].value,

              ActivityStatus: !res.data.activityStatus,
              ClosureDate: res.data.closureDate ? moment(res.data.closureDate) : null,
              dateOfIncorporationCompany: moment(
                res.data.dateOfIncorporationCompany
              ),
            });
            console.log(res.data.phoneOfCeo);
            console.log(res.data.mailOfCeo);
            const activitySubIds = [];
            if (res.data.activityCompanyCreateDtos) {
              res.data.activityCompanyCreateDtos.map((l) => {
                console.log(l);

                if (l.isMain) {
                  form.setFieldsValue({ [`activityId`]: l.activityId });
                } else {
                  activitySubIds.push(l.activityId);
                  setIsFocused((prev) => ({ ...prev, activitySubId: true }));
                }
              }
              );
              form.setFieldsValue({ [`activitySubId`]: activitySubIds });
            }
            const typeOfActivitySubIds = [];
            if (res.data.typeOfActivityCompanyCreateDtos) {
              res.data.typeOfActivityCompanyCreateDtos.map((l) => {
                if (l.isMain) {
                  form.setFieldsValue({ [`typeOfActivityId`]: l.typeOfActivityId });
                }
                else {
                  typeOfActivitySubIds.push(l.typeOfActivityId);

                  setIsFocused((prev) => ({ ...prev, typeOfActivitySubId: true }));
                }
              }
              );

              form.setFieldsValue({ [`typeOfActivitySubId`]: typeOfActivitySubIds });
            }
            res.data.socialNetworkofCompany.map((l) => {

              form.setFieldsValue({ [`social${l.type}`]: l.value });
            });
            res.data.socialNetworkofCeo.map((l) => {

              form.setFieldsValue({ [`ceosocial${l.type}`]: l.value });
            });
            form.setFieldsValue({ [`type`]: res.data.phoneOfCompany });



          })
          .catch((err) => {
            props.notify("Xəta baş verdi", false);
          });
      };
      getRegister();


      // formRef.current.setFieldsValue(data);
    }
    const getCountry = () => {
      aictia.get("Country").then((res) => {
        setCountry(res.data);
      });
    };
    const getStateOrPrivate = () => {
      aictia.get("StateorPrivate").then((res) => {
        setState(res.data);
      });
    };
    const getTypeOfActivity = () => {
      aictia.get("TypeOfActivity").then((res) => {
        setTypeOfActivity(res.data);
      });
    };
    const getActivity = () => {
      aictia.get("Activity").then((res) => {
        setActivity(res.data);
      });
    };
    const getAictiaPacket = () => {
      aictia.get("PacketOfAictia").then((res) => {
        setPacket(res.data);
      });
    };
    const getTypeOfCompany = () => {
      aictia.get("TypeOfCompany").then((res) => {
        setTypeOfCompany(res.data);
      });
    };
    getStateOrPrivate();
    getCountry();
    getTypeOfActivity();
    getActivity();
    getAictiaPacket();
    getTypeOfCompany();
    //form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.worker, t]);
  const checkTin = () => {
    setLoading(true);

    const tin = form.getFieldValue("tin");
    aictia.get(`ReyestrOfCompany/CheckTIN?TIN=${tin}`).then((res) => {
      setDisabled(true)
      setStatus(!res.data.activityStatus);
      setText({ note: res.data.note });
      if (res.data.country) {
        setIsFocused((prev) => ({ ...prev, countryId: true }));
      }
      if (res.data.authorizedCapital !== null || res.data.authorizedCapital !== undefined) {
        setIsFocused((prev) => ({ ...prev, authorizedCapital: true }));
      }
      if (res.data.typeOfCompany) {
        setIsFocused((prev) => ({ ...prev, typeOfCompanyId: true }));
      }
      if (res.data.stateorPrivate) {
        setIsFocused((prev) => ({ ...prev, stateorPrivateId: true }));
      }
      if (res.data.packetOfAictia) {
        setIsFocused((prev) => ({ ...prev, packetOfAictiaId: true }));
      }
      if (res.data.typeOfActivity) {
        setIsFocused((prev) => ({ ...prev, typeOfActivityId: true }));
      }
      if (res.data.activity) {
        setIsFocused((prev) => ({ ...prev, activityId: true }));
      }
      setCountryData(res.data.country?.id);


      form.setFieldsValue({
        countryId: res.data.country?.id,
        nameOfCompany: res.data.nameOfCompany,
        typeOfCompanyId: res.data.typeOfCompany?.id,
        dateOfIncorporationCompany: moment(res.data?.dateOfIncorporationCompany),
        officialCEOoftheCompany: res.data?.officialCEOoftheCompany,
        saleSign: res.data.saleSign,
        stateorPrivateId: res.data.stateorPrivate?.id,
        typeOfActivityId: res.data.typeOfActivityCompanyDtos?.filter((l) => l.isMain)
          .map((l) => {
            return l.id;
          }),
        typeOfActivitySubId: res.data.typeOfActivityCompanyDtos?.filter((l) => l.isMain == false)
          .map((l) => {
            return l.id;
          }),
        activityId: res.data.activityCompanyDtos?.filter((l) => l.isMain)
          .map((l) => {
            return l.id;
          }),
        activitySubId: res.data.activityCompanyDtos?.filter((l) => l.isMain == false)
          .map((l) => {
            return l.id;
          }),
        officialWebSite: res.data.officialWebSite,
        authorizedCapital: res.data.authorizedCapital ? res.data.authorizedCapital : 0,
        recognizedCeo: res.data.recognizedCeo,
        packetOfAictiaId: res?.data?.packetOfAictia?.id,
        ClosureDate: res.data.closureDate ? moment(res.data.closureDate) : null,
        legalAddress: res.data.legalAddress,
        actualAddress: res.data.actualAddress,
        workerCount: res.data.workerCount,
        phoneOfCompany: res.data.phoneOfCompany.map((l) => {
          return { value: l.value };
        }),
        officialMail: res.data.officialMail.map((l) => {
          return { value: l.value };
        }),
        phoneOfCeo: res.data.phoneOfCeo.map((l) => {
          return { value: l.value };
        }),
        mailOfCeo: res.data.mailOfCeo.map((l) => {
          return { value: l.value };
        }),
        note: res.data.note,
      });
      res.data.socialNetworkofCompany.map((l) => {
        form.setFieldsValue({ [`social${l.type}`]: l.value });
      }
      );
      res.data.socialNetworkofCeo.map((l) => {
        form.setFieldsValue({ [`ceosocial${l.type}`]: l.value });
      });
      form.setFieldsValue({ [`type`]: res.data.phoneOfCompany });

      // const activitySubIds = [];
      // if (res.data.activityCompanyCreateDtos) {
      //   res.data.activityCompanyCreateDtos.map((l) => {
      //     if (l.isMain) {
      //       form.setFieldsValue({ [`activityId`]: l.activityId });
      //     } else {
      //       activitySubIds.push(l.activityId);
      //       setIsFocused((prev) => ({ ...prev, activitySubId: true }));
      //     }
      //   }
      //   );
      //   form.setFieldsValue({ [`activitySubId`]: activitySubIds });
      // }
      // const typeOfActivitySubIds = [];
      // if (res.data.typeOfActivityCompanyCreateDtos) {
      //   res.data.typeOfActivityCompanyCreateDtos.map((l) => {


      //     if (l.isMain) {
      //       form.setFieldsValue({ [`typeOfActivityId`]: l.typeOfActivityId });
      //     }
      //     else {
      //       typeOfActivitySubIds.push(l.typeOfActivityId);

      //       setIsFocused((prev) => ({ ...prev, typeOfActivitySubId: true }));
      //     }
      //   }
      //   );
      //   form.setFieldsValue({ [`typeOfActivitySubId`]: typeOfActivitySubIds });
      // }
      // setCeoEmail(res.data.mailOfCeo.length);
      // setCeoPhoneNum(res.data.phoneOfCeo.length);
      // setPhoneNum(res.data.phoneOfCompany.length);
      // setEmail(res.data.officialMail.length);
      setText({ note: res.data.note });
      setSearchData(res.data);
      setCreate(false);
    })
      .catch((err) => {
        setDisabled(false)
        setCreate(true);
        const allFields = form.getFieldsValue();

        // Sadece belirtilen alan hariç tüm alanları sıfırla
        const fieldsToReset = Object.keys(allFields).filter(
          (key) => key !== "tin"
        );

        form.resetFields(fieldsToReset);

        //props.notify("Vöen tapılmadı", false);
        setCeoEmail(1);
        setCeoPhoneNum(1);
        setPhoneNum(1);
        setEmail(1);
        setText({});
        setSearchData([]);
      }
      ).finally(() => {
        setLoading(false)
      });

  }

  const antIcon = (
    <LoadingOutlined
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spin
    />
  );

  const handleValidation = (e) => {
    const value = e.target.value;
    setTin(value);

    const isWhitespace = value.trim() === "";
    const isValidLength = value.length === 10;
    const isOnlyNumbers = /^\d+$/.test(value);

    if (isWhitespace || !isValidLength || !isOnlyNumbers) {
      setDisabledTin(true);
    } else {
      setDisabledTin(false);
    }
  };


  const location = useLocation();
  const page = location.state?.page || 1;
  const [inputValues, setInputValues] = useState({ authorizedCapital: 0 });
  const saveItem = (values) => {
    setLoading(true);

    console.log(values);
    const { social1, social2, social3, social4, social5, ceosocial1, ceosocial2, ceosocial3, ceosocial4, ceosocial5, ...restValues } = values;
    const media = [
      {
        type: 1,
        socialmeda: "facebook",
      },
      {
        type: 2,
        socialmeda: "twitter",
      },
      {
        type: 3,
        socialmeda: "linkedin",
      },
      {
        type: 4,
        socialmeda: "youtube",
      },
      {
        type: 5,
        socialmeda: "wikipedia",
      },
    ];
    console.log(media);


    const data = {
      ...restValues,
      authorizedCapital: values.authorizedCapital ? values.authorizedCapital : 0,
      ActivityStatus: !status,
      packetOfAictiaId: Number(values.packetOfAictiaId) ? Number(values.packetOfAictiaId) : null,
      typeOfActivityCompanyCreateDtos: values?.typeOfActivitySubId ? [
        ...values?.typeOfActivitySubId?.map((l) => {
          return {
            typeOfActivityId: Number(l),
            isMain: false,
          };
        }),
        {
          typeOfActivityId: values.typeOfActivityId,
          isMain: true,
        }

      ] : [
        {
          typeOfActivityId: values.typeOfActivityId,
          isMain: true,
        }
      ],
      activityCompanyCreateDtos: values?.activitySubId ? [
        ...values?.activitySubId?.map((l) => {
          return {
            activityId: Number(l),
            isMain: false,
          };
        }),
        {
          activityId: values.activityId,
          isMain: true,
        }
      ] : [
        {
          activityId: values.activityId,
          isMain: true,
        }
      ],
      tin: form.getFieldValue("tin"),
      DateOfIncorporationCompany: values.dateOfIncorporationCompany,
      SocialNetworkofCompany: media?.map((l) => {
        return {
          type: String(l.type),
          value: values[`social${l.type}`],
        };
      }),
      SocialNetworkofCeo: media?.map((l) => {
        return {
          type: String(l.type),
          value: values[`ceosocial${l.type}`],
        };
      }),

    };
    if (props.id) {
      aictia
        .put(`ReyestrOfCompany/${props.id}`, { ...data, id: props.id })
        .then((res) => {
          form.resetFields();

          props.notify("Deyisdirildi ", true);
          window.history.back();
        })
        .catch((err) => {
          props.notify("Xəta baş verdi", false);
        }).finally(() => {
          setLoading(false)
        });
    } else {


      aictia
        .post(`ReyestrOfCompany`, data)
        .then((res) => {
          form.resetFields();

          props.notify("Əlavə olundu", true);
          window.history.back();
        })
        .catch((err) => {
          props.notify(err, false);
        }).finally(() => {
          setLoading(false)
        });
    }

  };
  const config = {
    readonly: props.view || disabled, // State'e bağlı olarak enable/disable
  };
  const handleInputClick = (name) => {

    form.getFieldValue(name) && window.open(form.getFieldValue(name), "_blank");
  };

  const handleChange = (name, value) => {
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };


  return (
    <div>
      <Form onFinish={saveItem} layout="vertical" form={form}>
        <div className={`commontask taskkk ${props.view ? 'detail' : ''}`}>
          <Card>
            <Row gutter={[16, 32]}>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item

                    name="tin"

                    validateTrigger="onChange"
                    rules={[
                      whiteSpace(t("inputError")),
                      // {
                      //   max: 10,
                      //   message: "10 simvol olmalıdır",
                      // },
                      // {
                      //   min: 10,
                      //   message: "10 simvol olmalıdır",
                      // },
                      // {
                      //   pattern: /^-?\d+(\.\d+)?$/,
                      //   message: "Sadəcə rəqəm olmalıdır",
                      // },
                      {
                        pattern: /^\d{10}$/, // Yalnız 10 rəqəm olmalıdır
                        message: "10 rəqəm olmalıdır",
                      },
                    ]}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <InputText disabled={props.id ? true : false} size={"large"} className='w-full ' value={tin} id="tin" maxLength={10} onChange={handleValidation} />
                      {
                        !props.view && !props.id && (loading ? <Spin indicator={antIcon} className="searchTin" /> : <SearchOutlined onClick={checkTin} disabled={disabledTin}
                          className="searchTin"
                          style={{
                            pointerEvents: disabledTin ? "none" : "auto",
                            opacity: disabledTin ? 0.5 : 1,
                          }} />)
                      }

                    </div>

                  </Form.Item>
                  <label htmlFor="tin">{t('tin')} <span style={{ color: 'red' }}>*</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="nameOfCompany"
                    validateTrigger="onChange"
                    rules={[whiteSpace(t("inputError"))]}
                  >


                    <InputText readOnly={props.view} disabled={disabled} size={"large"} id='nameOfCompany' className="w-full" />

                    {/* <Input readOnly={props.view} disabled={disabled} size={"large"} placeholder={t("nameOfEntity")} /> */}
                  </Form.Item>
                  <label htmlFor="nameOfCompany">{t('nameOfEntity')} <span style={{ color: 'red' }}>*</span></label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfCompanyId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >

                    <Select disabled={props.view || disabled} id='typeOfCompanyId' onFocus={() => handleFocus("typeOfCompanyId")}
                      onBlur={(value) => handleBlur("typeOfCompanyId", value)}>
                      {typeOfCompany.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>


                  </Form.Item>
                  <label htmlFor="typeOfCompanyId">{t('businessEntity')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="dateOfIncorporationCompany"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}

                  >

                    <DatePicker
                      onFocus={() => handleFocus("dateOfIncorporationCompany")}
                      onBlur={(value) => handleBlur("dateOfIncorporationCompany", value)}
                      placeholder=""
                      disabled={props.view || disabled}
                      className="w-100"
                      id="dateOfIncorporationCompany"
                    // value={date} 
                    // onChange={(e) => {
                    //   setDate(e.value); // Seçilen tarihi güncelle
                    //   form.setFieldsValue({ dateOfIncorporationCompany: e.value }); // Form değerini de güncelle
                    // }} 
                    />
                  </Form.Item>
                  <label htmlFor="dateOfIncorporationCompany">{t('registrationDate')}<span style={{ color: 'red' }}> *</span></label>
                  {/* <label htmlFor="dateOfIncorporationCompany" className={`custom-placeholder ${isFocused["dateOfIncorporationCompany"] ? "active" : ""}`}>{t('Qeydiyyat Tarixi')}<span style={{ color: 'red' }}>*</span></label> */}
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="legalAddress"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={props.view} disabled={disabled} size={"large"} id='legalAddress' className="w-full" />


                  </Form.Item>
                  <label htmlFor="legalAddress">{t('legalAddress')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="actualAddress"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={props.view} disabled={disabled} size={"large"} id='actualAddress' className="w-full" />


                  </Form.Item>
                  <label htmlFor="actualAddress">{t('actualAddress')}</label>
                </FloatLabel>
              </Col>


              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="workerCount"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={props.view} disabled={disabled} size={"large"} id='workerCount' className="w-full" />


                  </Form.Item>
                  <label htmlFor="workerCount">{t('employeeCount')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="authorizedCapital"
                    validateTrigger="onChange"
                  >
                    <InputNumber onFocus={() => handleFocus("authorizedCapital")}
                      onBlur={(value) => handleBlur("authorizedCapital", value)}
                      onChange={(value) => handleChange("authorizedCapital", value)}
                      readOnly={props.view} disabled={disabled} size={"large"} id='authorizedCapital' className="w-full" />


                  </Form.Item>
                  <label htmlFor="authorizedCapital">{t('charterCapital')}</label>

                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    //label={t('country')}
                    name="countryId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >

                    <Select disabled={props.view || disabled} id="country" onFocus={() => handleFocus("countryId")}
                      onBlur={(value) => handleBlur("countryId", value)}>
                      {country.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>



                  </Form.Item>
                  <label htmlFor="country">{t('country')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="stateorPrivateId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select disabled={props.view || disabled} id='statePrivate' onFocus={() => handleFocus("stateorPrivateId")}
                      onBlur={(value) => handleBlur("typeOfCostateorPrivateIdmpanyId", value)}>
                      {state.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="stateorPrivate">{t('statePrivate')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>
              {/* <Col md={6} sm={12} xs={24}>
              <FloatLabel>
                <Form.Item
                  name="officialCEOoftheCompany"
                  validateTrigger="onChange"
                >

                  <InputText readOnly={props.view} disabled={disabled} size={"large"} id='officialCEOoftheCompany' className="w-full" />


                </Form.Item>
                <label htmlFor="officialCEOoftheCompany">{t('officialRepresentative')}</label>
              </FloatLabel>
            </Col> */}
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="saleSign"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={props.view} disabled={disabled} size={"large"} id='saleSign' className="w-full" />


                  </Form.Item>
                  <label htmlFor="saleSign">{t('trademark')}</label>
                </FloatLabel>
              </Col>


              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="packetOfAictiaId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={props.view || disabled} id='membershipPackage' onFocus={() => handleFocus("packetOfAictiaId")}
                      onBlur={(value) => handleBlur("packetOfAictiaId", value)}>
                      {packet.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="membershipPackage">{t('membershipPackage')}</label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfActivityId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select disabled={props.view || disabled} id='typeOfActivity' onFocus={() => handleFocus("typeOfActivityId")}
                      onBlur={(value) => handleBlur("typeOfActivityId", value)}>
                      {typeOfActivity.filter(elem => !form.getFieldValue("typeOfActivitySubId")?.includes(elem.id)).map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="typeOfActivity">{t('typeOfActivity')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="typeOfActivitySubId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={props.view || disabled} id='typeOfActivitySubId' mode="multiple" maxTagCount={1}
                      maxTagPlaceholder={(omittedValues) => (
                        <span>+{omittedValues.length}</span>
                      )}
                      onFocus={() => handleFocus("typeOfActivitySubId")}
                      onBlur={(value) => handleBlur("typeOfActivitySubId", value)}>
                      {typeOfActivity.filter(elem => form.getFieldValue("typeOfActivityId") !== (elem.id))?.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="typeOfActivitySubId">{t('Əlavə fəaliyyət növü')}</label>
                </FloatLabel>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="activityId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select disabled={props.view || disabled} id='fieldOfActivity' onFocus={() => handleFocus("activityId")}
                      onBlur={(value) => handleBlur("activityId", value)}>
                      {activity.filter(elem => !form.getFieldValue("activitySubId")?.includes(elem.id)).map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="fieldOfActivity">{t('fieldOfActivity')}<span style={{ color: 'red' }}> *</span></label>
                </FloatLabel>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="activitySubId"
                    validateTrigger="onChange"
                  >
                    <Select disabled={props.view || disabled} id='activitySubId' mode="multiple" maxTagCount={1}
                      maxTagPlaceholder={(omittedValues) => (
                        <span>+{omittedValues.length}</span>
                      )}
                      onFocus={() => handleFocus("activitySubId")}
                      onBlur={(value) => handleBlur("activitySubId", value)}>
                      {activity.filter(elem => elem.id !== form.getFieldValue("activityId")).map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <label htmlFor="activitySubId">{t('Əlavə fəaliyyət sahəsi')}</label>
                </FloatLabel>
              </Col>


              {/* <Col md={6} sm={12} xs={24}>
              <FloatLabel>
                <Form.Item
                  name="officialWebSite"
                  validateTrigger="onChange"
                >

                  <InputText readOnly={props.view} disabled={disabled} size={"large"} id='officialWebsite' className="w-full" />


                </Form.Item>
                <label htmlFor="officialWebsite">{t('officialWebsite')}</label>
              </FloatLabel>
            </Col> */}

              {/* <Col md={6} sm={12} xs={24}>
              <FloatLabel>
                <Form.Item
                  name="recognizedCeo"
                  validateTrigger="onChange"
                >

                  <InputText readOnly={props.view} disabled={disabled} className="w-full" size={"large"} id='recognizedCeo' />

                </Form.Item>
                <label htmlFor="recognizedCeo">{t('recognizedLeader')}</label>
              </FloatLabel>
            </Col> */}

              <Col md={6} sm={12} xs={24}>
                <div className={(props.view || disabled) ? "status" : "active status"}>
                  <Form.Item
                    name="ActivityStatus"
                    validateTrigger="onChange"
                  >
                    Bağlanma statusu

                    <Switch checked={status} disabled={props.view || disabled} onChange={(value) => setStatus(value)} placeholder={t('Bağlanma statusu')} />
                  </Form.Item>
                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <FloatLabel>
                  <Form.Item
                    name="recognizedCejjo"
                    validateTrigger="onChange"
                  >

                    <InputText readOnly={props.view} disabled={true} className="w-full" size={"large"} id='recognizedCeo' />

                  </Form.Item>
                  <label htmlFor="recognizedCeo">{t('Təqdimatın adı')}</label>
                </FloatLabel>
              </Col>

            </Row>
          </Card>
          <Card>
            <Row gutter={[16, 16]}>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('Şirkət profili')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialWebSite"
                        validateTrigger="onChange"
                      // rules={[
                      //   {
                      //     pattern: /^(https?:\/\/)/,
                      //     message: "Linki protokolla (http:// və ya https://) yazın",
                      //   },
                      // ]}
                      >

                        <InputText readOnly={props.view} disabled={disabled} size={"large"} id='officialWebsite' className="w-full" />


                      </Form.Item>
                      <label htmlFor="officialWebsite">{t('officialWebsite')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(email)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["officialMail", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText readOnly={props.view} disabled={disabled} size={"large"} id='officialEmail' className="w-full" />


                            </Form.Item>
                            <label htmlFor="officialEmail">{t('officialEmail')}</label>
                          </FloatLabel>
                        </div>
                        <div className="flex">
                          <div onClick={() => !disabled ? setEmail(email + 1) : null}
                            className={`${email > 1 && "mr-10"} ${!disabled ? "pointer" : ""}`}><PlusOutlined />
                          </div>
                          {
                            email > 1 &&
                            <div onClick={() => email > 1 && !disabled && setEmail(email - 1)}
                              className={`${!disabled ? "pointer" : ""}`}
                            ><MinusOutlined />
                            </div>
                          }
                        </div>
                      </div>
                    ))}

                  </div>
                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(phoneNum)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["phoneOfCompany", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText readOnly={props.view} disabled={disabled} size={"large"} id='PhoneOfCompany' className="w-full" />


                            </Form.Item>
                            <label htmlFor="PhoneOfCompany">{t('companyPhone')}</label>
                          </FloatLabel>
                        </div>
                        <div className="flex">
                          <div onClick={() => !disabled ? setPhoneNum(phoneNum + 1) : null}
                            className={`${phoneNum > 1 && "mr-10"} ${!disabled ? "pointer" : ""}`}><PlusOutlined />
                          </div>
                          {
                            phoneNum > 1 &&
                            <div onClick={() => phoneNum > 1 && !disabled && setPhoneNum(phoneNum - 1)}
                              className={` ${!disabled ? "pointer" : ""}`}
                            ><MinusOutlined />
                            </div>
                          }

                        </div>
                      </div>
                    ))}

                  </div>
                  {socialMedia.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={elem.name}
                        // rules={[
                        //   {
                        //     pattern: /^(https?:\/\/)/,
                        //     message: "Linki protokolla (http:// və ya https://) yazın",
                        //   },
                        // ]}
                        >
                          <InputText readOnly={props.view} id={elem.title} disabled={disabled} className="w-full" onClick={() => props.view ? handleInputClick(elem.name) : null} />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>

              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('officialRepresentative')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialCEOoftheCompany"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={disabled} size={"large"} id='officialCEOoftheCompany' className="w-full" />


                      </Form.Item>
                      <label htmlFor="officialCEOoftheCompany">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(ceoEmail)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              className={"mb-0 w-100 mr-10"}
                              validateTrigger="onChange"
                              name={["mailOfCeo", index, "value"]}
                            >
                              <InputText readOnly={props.view} disabled={disabled} size={"large"} id='mailOfCeo' className="w-full" />
                            </Form.Item>
                            <label htmlFor="mailOfCeo">{t('ceoEmail')}</label>
                          </FloatLabel>
                        </div>
                        <div className="flex">
                          <div onClick={() => !disabled ? setCeoEmail(ceoEmail + 1) : null}
                            className={`${ceoEmail > 1 && "mr-10"} ${!disabled ? "pointer" : ""}`}><PlusOutlined />
                          </div>
                          {
                            ceoEmail > 1 &&
                            <div onClick={() => ceoEmail > 1 && !disabled && setCeoEmail(ceoEmail - 1)}
                              className={` ${!disabled ? "pointer" : ""}`}
                            ><MinusOutlined />
                            </div>
                          }

                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mb-30 flex dir-column gap-12" >
                    {[...Array(ceoPhoneNum)].map((_, index) => (
                      <div className="flex flex-align-center flex-between">
                        <div className="w-full mr-5 flex dir-column gap-12">
                          <FloatLabel>
                            <Form.Item
                              name={["phoneOfCeo", index, "value"]}
                              validateTrigger="onChange"
                              className={"mb-0 w-100 mr-10"}
                            >

                              <InputText readOnly={props.view} disabled={disabled} size={"large"} id='phoneOfCeo' className="w-full" />


                            </Form.Item>
                            <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                          </FloatLabel>
                        </div>
                        <div className="flex">
                          <div onClick={() => !disabled ? setCeoPhoneNum(ceoPhoneNum + 1) : null}
                            className={`${ceoPhoneNum > 1 && "mr-10"} ${!disabled ? "pointer" : ""}`}><PlusOutlined />
                          </div>
                          {
                            ceoPhoneNum > 1 &&
                            <div onClick={() => ceoPhoneNum > 1 && !disabled && setCeoPhoneNum(ceoPhoneNum - 1)}
                              className={` ${!disabled ? "pointer" : ""}`}
                            ><MinusOutlined />
                            </div>
                          }

                        </div>
                      </div>
                    ))}
                    {/* <FloatLabel>
                      <Form.Item
                        name="phoneOfCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={disabled} size={"large"} id='phoneOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                    </FloatLabel> */}
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={elem.name}
                        // rules={[
                        //   {
                        //     pattern: /^(https?:\/\/)/,
                        //     message: "Linki protokolla (http:// və ya https://) yazın",
                        //   },
                        // ]}
                        >
                          <InputText readOnly={props.view} disabled={disabled} id={elem.title} className="w-full" onClick={() => props.view ? handleInputClick(elem.name) : null} />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>


              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('recognizedLeader')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="recognizedCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={disabled} className="w-full" size={"large"} id='recognizedCeo' />

                      </Form.Item>
                      <label htmlFor="recognizedCeo">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="mailOrrfCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={true} size={"large"} id='mailOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="mailOfCeo">{t('Şəxsin maili')}</label>
                    </FloatLabel>
                  </div>
                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="phoneOrrfCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={true} size={"large"} id='phoneOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                    </FloatLabel>
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={'fjhhfhf'}
                        >
                          <InputText readOnly={props.view} disabled={true} id={elem.title} className="w-full" onClick={() => props.view ? handleInputClick(elem.name) : null} />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>
              <Col md={6} sm={12} xs={24}>
                <div className="p-2 border">
                  <div className="flex mb-15  flex-align-center flex-between">
                    <span>{t('Əlaqəli şəxs')}</span>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="officialCEOoftheCompffany"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={true} size={"large"} id='officialCEOoftheCompany' className="w-full" />


                      </Form.Item>
                      <label htmlFor="officialCEOoftheCompany">{t('Ad,Soyad')}</label>
                    </FloatLabel>
                  </div>

                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="mailOfffCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={true} size={"large"} id='mailOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="mailOfCeo">{t('Şəxsin maili')}</label>
                    </FloatLabel>
                  </div>
                  <div className="mb-30 " >
                    <FloatLabel>
                      <Form.Item
                        name="phoneOfffCeo"
                        validateTrigger="onChange"
                      >

                        <InputText readOnly={props.view} disabled={true} size={"large"} id='phoneOfCeo' className="w-full" />


                      </Form.Item>
                      <label htmlFor="phoneOfCeo">{t('directorPhone')}</label>
                    </FloatLabel>
                  </div>
                  {socialMediaCeo.map((elem, index) => (
                    <div className="mb-30 " key={index}>
                      <FloatLabel>
                        <Form.Item
                          validateTrigger="onChange"
                          name={'djdjd'}
                        >
                          <InputText readOnly={props.view} disabled={true} id={elem.title} className="w-full" onClick={() => props.view ? handleInputClick(elem.name) : null} />
                        </Form.Item>
                        <label htmlFor={elem.title}>{elem.title}</label>
                      </FloatLabel>
                    </div>
                  ))}

                </div>
              </Col>


              {/* <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t("directorPhone")}</span>
                </div>
                {[...Array(ceoPhoneNum)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["phoneOfCeo", index, "value"]}
                      rules={[
                        {
                          pattern: /^[^a-zA-Z]*$/,
                          message: "Hərflər olmamalıdır!",
                        }
                      ]}
                    >
                      <Input readOnly={props.view} disabled={disabled} placeholder={"+994XXXXXXXXXX"} />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setCeoPhoneNum(ceoPhoneNum + 1)}
                    className={"mr-10"}
                    disabled={props.view || disabled}
                    type={"primary"}
                    shape={"circle"}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() =>
                      ceoPhoneNum > 1 && setCeoPhoneNum(ceoPhoneNum - 1)
                    }
                    disabled={props.view || disabled}
                    type={"primary"}
                    shape={"circle"}
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col> */}
              {/* <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('ceoEmail')}</span>
                </div>
                {[...Array(ceoEmail)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["mailOfCeo", index, "value"]}
                    >
                      <Input readOnly={props.view} disabled={disabled} />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setCeoEmail(ceoEmail + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    disabled={props.view || disabled}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => ceoEmail > 1 && setCeoEmail(ceoEmail - 1)}
                    type={"primary"}
                    shape={"circle"}
                    disabled={props.view || disabled}
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col> */}

              {/* <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('companyPhone')}</span>
                </div>
                {[...Array(phoneNum)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["phoneOfCompany", index, "value"]}
                      rules={[
                        {
                          pattern: /^[^a-zA-Z]*$/,
                          message: "Hərflər olmamalıdır!",
                        }
                      ]}
                    >
                      <Input readOnly={props.view} disabled={disabled} placeholder={"+994XXXXXXXXXX"} />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setPhoneNum(phoneNum + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<PlusOutlined />}
                    disabled={props.view || disabled}
                  />
                  <Button
                    onClick={() => phoneNum > 1 && setPhoneNum(phoneNum - 1)}
                    type={"primary"}
                    shape={"circle"}
                    icon={<MinusOutlined />}
                    disabled={props.view || disabled}
                  />
                </div>
              </div>
            </Col> */}
              {/* <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('officialEmail')}</span>
                </div>
                {[...Array(email)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["officialMail", index, "value"]}
                    >
                      <Input readOnly={props.view} disabled={disabled} />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setEmail(email + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    disabled={props.view || disabled}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => email > 1 && setEmail(email - 1)}
                    type={"primary"}
                    shape={"circle"}
                    disabled={props.view || disabled}
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col> */}
              {/* <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('companySocialMedia')}</span>
                </div>
                {socialMedia.map((elem, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={elem.name}
                      label={elem.title}
                    >
                      <Input readOnly={props.view} disabled={disabled} onClick={() => props.view ? handleInputClick(elem.name) : null} />
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('directorSocialMedia')}</span>
                </div>
                {socialMediaCeo.map((elem, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={elem.name}
                      label={elem.title}
                    >
                      <Input readOnly={props.view} disabled={disabled} onClick={() => props.view ? handleInputClick(elem.name) : null} />
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Col> */}

            </Row>
          </Card>
        </div>
        <Col sm={12} xs={24}>
          <div className="custom-select-wrapper ">
            <Form.Item
              name="note"
              validateTrigger="onChange"
            >
              <JoditEditor
                onChange={(newContent) => {
                  form.setFieldsValue({ note: newContent });
                  //handleFocus("note");
                }}
                // onBlur={() => {
                //   const content = editor.current?.editor?.getContent() || ""; // İçeriği al
                //   handleBlur("note", content.trim()); // Boşsa blur event'ini çalıştır
                // }}
                className={"mb-15"}
                config={config}
                ref={editor}
                value={text["note"]}
                tabIndex={1}
              />

            </Form.Item>
            {/* <label htmlFor="country" className={`custom-placeholder ${isFocused["note"] ? "active" : ""}`}>{t('note')}</label> */}

          </div>
        </Col>

        {
          !props.view && (
            <div
              className="modalButtons"
              style={{ position: "absolute", bottom: "20px", right: "40px" }}
            >
              <Link to={{ pathname: `/register?page=${page}` }}>
                <Button>{t("cancel")}</Button>
              </Link>
              <Button type="primary" className="ml-10" htmlType="submit" loading={loading}>
                {t("save")}
              </Button>
            </div>
          )
        }

      </Form>
    </div>
  );
};

export default connect(null, { notify })(Company);
