import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Button, Tooltip, Collapse } from "antd";
import {
  QuestionCircleOutlined,
  BulbFilled,
  InfoCircleOutlined,
  RetweetOutlined,
  WarningOutlined,
  BellFilled,
  LogoutOutlined,
  SettingOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  UpOutlined
} from "@ant-design/icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { changeLanguage } from "../../redux/actions";
import { Badge, Popover, Avatar, Row, Col } from "antd";
import { logOut } from "../../redux/actions";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import az from "../../assets/img/az.svg";
import en from "../../assets/img/us.svg";
import ru from "../../assets/img/ru.svg";
import tr from "../../assets/img/tr.png";
import man from "./../../assets/img/man.jpg";
import woman from "./../../assets/img/women.jfif";
import CollapseMenu from "./collapseMenu";
const BottomMenu = (props) => {
  const [lang, setLang] = useState("az");
  const [flag, setFlag] = useState(az);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory()
  const changeLang = (lang) => {
    const { i18n } = props;
    i18n.changeLanguage(lang);

    localStorage.setItem("locale", lang);
    props.changeLanguage(lang);
    setLang(lang);
  };
  useEffect(() => {
    setLang(
      localStorage.getItem("locale") ? localStorage.getItem("locale") : "az"
    );


    switch (lang) {

      case "az":
        setFlag(az);
        break;
      case "en":
        setFlag(en);
        break;
      case "ru":
        setFlag(ru);
        break;
      case "tr":
        setFlag(tr);
        break;
      default:
        setFlag(az);
        break;
    }
  }, [lang]);

  const menu = (
    <Menu>
      <div className="pl-1">
        <p>Dil seçimi</p>
      </div>
      <Menu.Item key="az" onClick={() => changeLang("az")}>
        <div className="flex">
          <span className="mr5-10">
            <img src={az} alt="" />
          </span>
          <span>Azərbaycanca</span>
        </div>
      </Menu.Item>
      <Menu.Item key="en" onClick={() => changeLang("en")}>
        <div className="flex">
          <span className="mr5-10">
            <img src={en} alt="" />
          </span>
          <span>English</span>
        </div>
      </Menu.Item>
      <Menu.Item key="ru" onClick={() => changeLang("ru")}>
        <div className="flex">
          <span className="mr5-10">
            <img src={ru} alt="" />
          </span>
          <span>Русский</span>
        </div>
      </Menu.Item>
      <Menu.Item key="tr" onClick={() => changeLang("tr")}>
        <div className="flex">
          <span className="mr5-10">
            <img src={tr} alt="" className="tr-flag" />
          </span>
          <span>Türkcə</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  const menu2 = (
    <Menu>
      <div className="pl-1">
        <p>Dəstək</p>
      </div>
      <Menu.Item>
        <div className="flex">
          <span className="mr5-10">
            <InfoCircleOutlined />
          </span>
          <span>Istifadə qaydaları</span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex">
          <span className="mr5-10">
            <WarningOutlined />
          </span>
          <span>İstifadə şərtləri</span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="flex">
          <span className="mr5-10">
            <RetweetOutlined />
          </span>
          <span>Geri bildirim</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  const { t } = useTranslation();
  const logOut = () => {
    localStorage.removeItem("access_token");
    props.logOut();
    history.push("/");
  };
  const content = () => {
    return (
      <div className="profil-info">
        <Row className="border-bottom pt-1 pb-1 mb-10">
          <Col xs={4}>
            <Avatar size={38} src={props.gender === 0 ? man : woman} />
          </Col>
          <Col className="border-right" xs={20}>
            <div className="flex w-100 h-100 flex-align-center pr-1">
              <h3>{props.name}</h3>
            </div>
          </Col>
        </Row>
        <div className="w-100  flex flex-between">
          <Button>
            <SettingOutlined /> <span>{t("settings")}</span>
          </Button>
          <Button onClick={logOut}>
            <span>{t("logOut")}</span> <LogoutOutlined />
          </Button>
        </div>
      </div>
    );
  };
  const role = localStorage.getItem("role");

  return (
    <div className="bottom-menu border-top">


      <div className="settings-menu">
        <div className="ml-5">


        </div>
        {
          role === "Admin" && (
            <Collapse
              className=""
              ghost
              accordion={true}
              expandIconPosition="right"
              expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
              
            >
              <Collapse.Panel header={<div className="border-none settings-alt">
                <SettingOutlined /><span className=" name-button ml-15">İnzibatçı</span>
              </div>} >
                <CollapseMenu />
              </Collapse.Panel>
            </Collapse>
          )
        }


        <div >

        </div>
        <div >
          <Tooltip placement="right" title={t("logOut")}>
            <Button onClick={logOut} className="border-none settings-alt">
              <LogoutOutlined /> <div className="name-button">{t("logOut")}</div>
            </Button>
          </Tooltip>
        </div>

        {/* <div className="top-menu-navigation">
    
              <Tooltip placement="bottomRight" title={props.name}>
                <Popover
                  placement="bottomRight"
                  content={content()}
                  trigger="click"
                >
                  <Avatar size={35} src={props.gender === 0 ? man : woman} />
                </Popover>
              </Tooltip>
              <Link to={`/reports`}>
                <Tooltip placement="bottomRight" title={t("notifications")}>
                  <Badge>
                    <Avatar
                      className="circle-notification"
                      size={35}
                      icon={<BellFilled />}
                    />
                  </Badge>
                </Tooltip>
              </Link>
            </div> */}
      </div>
      {/* )
        }
        */}
      <div className="other-bottom">
        <div >
          <Dropdown overlay={menu} placement="topLeft">
            <img src={flag} alt="" />
          </Dropdown>
        </div>
        <div className="icons">
          <span>
            <Dropdown overlay={menu2} placement="topRight">
              <Button shape="circle" className="border-none">
                <QuestionCircleOutlined />
              </Button>
            </Dropdown>
          </span>
          <span>
            <Tooltip placement="right" title="Dark mode">
              <Button shape="circle" className="border-none">
                <BulbFilled />
              </Button>
            </Tooltip>
          </span>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    name: user.data.user,
    gender: user.data.userGender,
  };
};
export default connect(mapStateToProps, { changeLanguage, logOut })(withTranslation()(BottomMenu));
