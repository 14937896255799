
import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Row,
  Col,
  Table,
  Spin,
  Select,
  Form,
  Input,
  DatePicker,
  Pagination
} from "antd";
import { convertColumns } from "../../../../utils/columnconverter";
import {
  ClearOutlined,
  ContainerOutlined
} from "@ant-design/icons";

import {  notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../../../const/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
const { Option } = Select;
const { RangePicker } = DatePicker;
function Logs() {
  let mainUrl = "ReyestrOfCompany";
  const [form] = Form.useForm();
  const [log, setLog] = useState([]);
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [input, setInput] = useState(null);
  const [user, setUser] = useState([]);
  const [page,setPage] = useState(1);
  const [pageSize,setPageSize] = useState(20);
  const [filter, setFilter] = useState(null);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    {
      key: "userName",
      value: t("İstifadəçi"),
      con: true,
    },
    {
      key: "roleName",
      value: "Səlahiyyəti",
      con: true,
    },
    { key: "companyName", value: "Şirkətin adı", con: false },
    { key: "operationName", value: "Əməliyyatın adı", con: false },

    { key: "operationDate", value: "Əməliyyat tarixi", con: false },
  ];
  const columns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
    },
    {
      title: 'İstifadəçi',
      dataIndex: "userName",
      key: "3",
    },
    {
      title: 'Səlahiyyəti',
      dataIndex: "roleName",
      key: "6",
    },
    {
      title: 'Şirkətin adı',
      dataIndex: "companyName",
      key: "8",
    },
    {
      title: 'Əməliyyatın adı',
      dataIndex: "operationName",
      key: "9",
    },
    {
      title: 'Əməliyyat tarixi',
      dataIndex: "operationDate",
      key: "10",
      render : (text) => moment(text).format("DD-MM-YYYY HH:mm")
    },
  ];
  const getLog = () => {
    setSpin(true);
    const params = {
      PerDataCount: (page-1) * pageSize,
      GetDataCount: pageSize,
      UserName: filter?.UserName,
      RoleName: filter?.RoleName,
      OperationStartDate: filter?.OperationStartDate,
      OperationEndDate: filter?.OperationEndDate

    };

    aictia.get("OperationLog",{params}).then((res) => {
      res.data && setSpin(false);
      setLog(
        res.data.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            tableIndex: index + 1 + (page-1)*pageSize,
          };
        })
      );
      setCount(res.data.count)
    });
  };
  const onSearch = (values) => {
    const params = {
      ...values,
      OperationStartDate: values.OperationStartDate && values.OperationStartDate[0].format("YYYY-MM-DD"),
      OperationEndDate: values.OperationStartDate && values.OperationStartDate[1].format("YYYY-MM-DD"),
      PerDataCount: (page-1) * pageSize,
      GetDataCount: pageSize,
    };
    setFilter(params);
    setSpin(true);
    aictia
      .get("OperationLog", { params })
      .then((res) => {
        setSpin(false);
        setLog(
          res.data.data.map((d, index) => {
            return {
              ...d,
              key: index + 1,
              index,
              tableIndex:  res.data.count - index - (page-1)*pageSize ,
            };
          })
        );
        setCount(res.data.count)
      })
      .catch((err) => {
        setSpin(false);
        notify(err.response.data, false);
      });
  };
  const clearFilter = () => {
    form.resetFields();
    setInput(null);
    setFilter(null);
    getLog();
  };
  const getUser = () => {
    aictia.get("User").then((res) => {
      setUser(res.data);
    });
  };


  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    console.log("page",page);
    getLog();
  }, [page, pageSize, t]);

  

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <ContainerOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">{t("Loqlar")}</span>
            </div>
         
          </div>
        </Col>



        <Col xs={24}>
          <Form onFinish={onSearch} layout="vertical" form={form}>
            <div className="commontask bg-white px-2 pt-15 pb-15">
              <Row className={"mt-5"} gutter={[16, 8]}>
                <Col md={7} sm={13} xs={24}>
                  <Form.Item
                    name={"OperationStartDate"}
                    // rules={[noWhitespace(t("inputError"))]}
                  >
                   <RangePicker size={"large"} style={{width:'100%'}}  placeholder={["Başlanğıc tarixi", "Son tarix"]} />
                  </Form.Item>
                </Col>
                <Col md={4} sm={12} xs={24}>
                  <Form.Item
                    name={"UserName"}
                    // rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select placeholder={t("İstifadəçi")}>
                      {user.map((p, index) => {
                        return (
                          <Option key={index} value={p.username}>
                            {p.username}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={4} sm={12} xs={24}>
                  <Form.Item
                    name={"RoleName"}
                    // rules={[noWhitespace(t("inputError"))]}
                  >
                    <Select placeholder={t("Səlahiyyət")}>
                       <Option value="Admin">Admin</Option>
                        <Option value="Inspector">Inspector</Option>
                        <Option value="Organization">Organization</Option>

                    </Select>
                  </Form.Item>
                </Col>
                <Col md={4} sm={12} xs={24}>
                  <Form.Item
                    name={"CompanyName"}
                    // rules={[noWhitespace(t("inputError"))]}
                  >
                    <Input placeholder={t("Şirkətin adı")} size={"large"} />
                  </Form.Item>
                </Col>
                <Col md={2} sm={12} xs={24}>
        
                    <Button
                      type="primary"
                      size={"large"}
                      className="w-100 f-13"
                      htmlType="submit"
                    >
                      {t("search")}
                    </Button>
        
                </Col>

                <Col md={2} sm={12} xs={24}>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={() => {
                        clearFilter();
                      }}
                      className="w-100"
                      htmlType="submit"
                    >
                      <ClearOutlined />
                    </Button>
       
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
        <>
          {spin ? (
            <Col xs={24}>
              <div className="flex animated fadeInUp bg-white all-center p-2">
                <Spin size={"large"} />
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <Table
                size="small"
                className="bg-white animated fadeIn"
                columns={columns}
                dataSource={convertColumns(log, cols)}
                pagination={{
                  pageSize: pageSize,
                  current: page,
                  total: count,
                  locale: { items_per_page: "/ səhifələnmə" },

                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);

                  }
                }}
              />
            </Col>
          )}
        </>
      </Row>
    </div>
  );
}

export default connect(null, { notify })(Logs);
