import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  DatePicker,
  Form,
  InputNumber,
  Popconfirm,
  Tooltip,
  Table,
  Switch,
} from "antd";
import { useTranslation } from "react-i18next";
import { noWhitespace, whiteSpace } from "../../../utils/rules";
import { notify } from "../../../redux/actions";
import aictia from "../../../const/api";
import moment from "moment";
import { connect } from "react-redux";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import history from "../../../const/history";
import { convertColumns } from "../../../utils/columnconverter";
import {
  UnorderedListOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import Element from "antd/lib/skeleton/Element";
// import ProjectTablee from "../project/table"
const { Option } = Select;

function Product(props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const mainUrl = "Product";

  const [fieldOfUse, setFieldOfUse] = useState([]);
  const [typeOfProject, setTypeOfProject] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoriesType, setCategoriesType] = useState([]);
  const [cutValue, setCutValue] = useState();
  const [subDisable, setSubDisable] = useState(true);
  const [typeDisable, setTypeDisable] = useState(true);
  const [positions, setPositions] = useState([]);
  const [spin, setSpin] = useState(false);
  const [editing, setEditing] = useState(null);
  const [field, setField] = useState([]);
  const [projectCate, setProjectCate] = useState([]);
  const [types, setTypes] = useState([]);
  const [techArray, setTechArray] = useState([0]);
  const [isEnd, setIsEnd] = useState(false);
  const editor = useRef();
  const nameInput = useRef();

  // props
  const { notify } = props;

  const getFieldOfUse = () => {
    aictia.get("UsageArea").then((res) => {
      setFieldOfUse(res.data);
    });
  };

  const getTypes = () => {
    aictia.get("Types").then((res) => {
      setTypes(res.data);
    });
  };
  //
  const getTypeOfProject = () => {
    aictia.get("TypeOfProduct").then((res) => {
      setTypeOfProject(res?.data);
    });
  };
  //cate
  const getCategories = () => {
    aictia.get("TechnologyFilter/TechnologyFields").then((res) => {
      setCategories(res.data.technologyCagegory);
    });
  };

  //subcate
  const getSubcategories = (value, index) => {
    let cate = subCategories;

    cate = cate.filter((elem) => elem.index !== index);

    const subCate = categories.find(
      (item) => item.id === value
    ).technologySubCagegory;
    setSubCategories([...cate, { index: index, array: subCate }]);

    setSubDisable(false);
    form.resetFields([["productCategories", index, "technologySubcategoryId"]]);
    form.resetFields([["productCategories", index, "technologyTypeId"]]);
  };

  //type
  const getCategoriesType = (value, index) => {
    let cateType = categoriesType;
    cateType = cateType.filter((item) => item.index !== index);
    const data = subCategories.find((item) => item.index === index).array;
    const type = data.find((item) => item.id === value).technologyType;

    setCategoriesType([...cateType, { index: index, array: type }]);

    setTypeDisable(false);
    form.resetFields([["productCategories", index, "technologyTypeId"]]);
  };

  const setEditingObject = async (i) => {
    setEditing(i);

    await aictia.get(mainUrl + `/${i}`).then((res) => {
      setSubDisable(false);
      setTypeDisable(false);
      setField(res.data.productUsageAreas);
      setProjectCate(res?.data.productCategories);

      const techArray1 = [];
      let subCategoriesModify = [];
      let categoriesTypeModify = [];
      if(res.data.productCategories.length !== 0){
      res.data.productCategories.map((item, index) => {
        techArray1.push(index);

        //subCate
        const subCate = categories.find(
          (elem) => elem.id === item.technologyCategoryId
        ).technologySubCagegory;
        subCategoriesModify = [
          ...subCategoriesModify,
          { index: index, array: subCate },
        ];

        //type
        const data = subCategoriesModify.find(
          (elem) => elem.index === index
        ).array;
        const type = data.find(
          (elem) => elem.id === item.technologySubcategoryId
        ).technologyType;
        categoriesTypeModify = [
          ...categoriesTypeModify,
          { index: index, array: type },
        ];
      });
    }else{
      techArray1.push(0);
      setSubDisable(true);
      setTypeDisable(true);
    }
      setSubCategories(subCategoriesModify);
      setCategoriesType(categoriesTypeModify);
      setTechArray(techArray1);

      setTimeout(() => {
        form.setFieldsValue({
          ...res.data,
          release_date: moment(res.data.release_date),
          usageAreaId: res.data.productUsageAreas.map(
            (item) => item.usageAreaId
          ),
        });
      }, 1000);
    });
  };

  const getPositions = async () => {
    setSpin(true);
    await aictia.get(`${mainUrl}?companyid=${props.id}`).then((res) => {
      setSpin(false);
      setPositions(
        res.data.map((p, index) => {
          return {
            key: index + 1,
            ...p,
            index: index + 1,
          };
        })
      );
    });
  };

  const deletePosition = async (i) => {
    await aictia
      .delete(`${mainUrl}/${i}`)
      .then(() => {
        // description
        notify("silindi", true);
        getPositions();
      })
      .catch((err) => {
        //error
        notify(err.response, false);
      });
  };

  const cancelEditing = () => {
    setSubDisable(true);
    setTypeDisable(true);
    setEditing(null);
    setTechArray([0]);
    form.resetFields();
  };
  const save = async (values) => {
    console.log(values);
    
    const data = {
      ...values,
      reyestrOfCompanyId: props.id,
      productCategories: values.productCategories[0].technologyCategoryId ? values.productCategories : [],
      endDate: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : null,
      productUsageAreas: values.usageAreaId ?  values?.usageAreaId?.map((item) => {
        return {
          usageAreaId: item,
        };
      }) : []
    };
    if (!editing) {
      await aictia
        .post(mainUrl, data)
        .then((res) => {
          notify("", true);
          getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      //field
      const dataUsageAreaIds = data?.productUsageAreas.map(
        (item) => item.usageAreaId
      );
      const updateFieldsIds = field.map((elem) => elem.usageAreaId);
      const updatedField = field.map((item) => {
        const isActive = dataUsageAreaIds.includes(item.usageAreaId);
        return { ...item, isActive };
      });
      data.productUsageAreas.map((elem) => {
        elem.isActive = true;
      });
      data.productUsageAreas = data?.productUsageAreas.filter(
        (elem) => !updateFieldsIds.includes(elem.usageAreaId)
      );
      data.productUsageAreas.push(...updatedField);
      const modifyData = values?.productCategories.filter((item) => item !== undefined);
      const projectCategoryIds =modifyData.map((item) => item.id);
      const updatedProjectCate = projectCate.map((item) => {
        const isActive = projectCategoryIds.includes(item.id);
        return { ...item, isActive };
      });
      modifyData.map((elem) => {
        elem.isActive = true;
      });
      modifyData.push(
        ...updatedProjectCate.filter((elem) => elem.isActive === false)
      );
      console.log(modifyData);
      
      data.productCategories = modifyData;

      await aictia
        .put(`${mainUrl}/${editing}`, { ...data, id: editing })
        .then((res) => {
          notify("", true);
          getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  useEffect(() => {
    getFieldOfUse();
    getTypeOfProject();
    getCategories();
    getPositions();
    getTypes()
  }, []);

  const cols = [
    { key: "index", value: "#", con: true },
    { key: "productName", value: "Məhsulun adı", con: true },
    { key: "productMarker", value: "Yaradıcı", con: true },
    { key: "typeOfProduct", value: "Məhsulun növü", con: true },
    { key: "id", value: "", con: false },
  ];
  const columns = [
    {
      title: "#",
      key: "1",
      dataIndex: "index",
      width: 80,
    },
    {
      title: "Məhsulun adı",
      key: "2",
      dataIndex: "productName",
    },
    {
      title: "Yaradıcı",
      key: "3",
      dataIndex: "productMaker",
    },
    {
      title: "Bazara buraxılma tarixi",
      dataIndex: "release_date",
      key: "4",
      render: (i) => {
        return i ?  moment(i).format("YYYY-MM-DD") : null
      },
    },
    {
      title: "Məhsulun növü",
      dataIndex: "typeOfProduct",
      key: "6",
      render: (i) => {
        return <p>{i.name}</p>;
      },
    },
    {
      title: "Büdcə",
      dataIndex: "budget",
      key: "7",
      render: (i) => {
        return <p>{i} azn</p>;
      },
    },
    {
      title: "",
      key: "8",
      dataIndex: "id",
      width: 30,
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePosition(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => setEditingObject(i)}
              >
                <EditFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const handleDeleteRow = (element) => {
    if (techArray.length > 1) {
      setTechArray(techArray.filter((elem) => elem !== element));
      form.resetFields([
        ["productCategories", element, "technologyCategoryId"],
      ]);
      form.resetFields([
        [["productCategories", element, "technologySubcategoryId"]],
      ]);
      form.resetFields([[["productCategories", element, "technologyTypeId"]]]);
    }
  };

  return (
    <>
      <div className="bg-white p-2">
        <Form layout="vertical" onFinish={save} form={form}>
          <Row gutter={[16, 16]}>
            <Col md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("Məhsulun adı")}
                  name="productName"
                  validateTrigger="onChange"
                  rules={[noWhitespace(t("inputError"))]}
                >
                  <Input size={"large"} />
                </Form.Item>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
            <div className="form-lang">

              <Form.Item
                label={"Məhsulun növü"}
                name="typeOfProductId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {typeOfProject.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("Yaradıcı")}
                  name="productMaker"
                  validateTrigger="onChange"
                >
                  <Input size={"large"} />
                </Form.Item>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
            <div className="form-lang">

              <Form.Item name="release_date" label={t("Bazara buraxılma tarixi")} validateTrigger="onChange">
                <DatePicker placeholder={t("Tarixi seçin")} className="w-100" />
              </Form.Item>
              </div>
            </Col>

            <Col md={6} sm={12} xs={24}>
            <div className="form-lang">
              <Form.Item
                label={"İstifadə sahəsi"}
                name="usageAreaId"
                validateTrigger="onChange"
                //   rules={[whiteSpace(t("inputError"))]}
              >
                <Select
                  mode="multiple"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {fieldOfUse.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              </div>
            </Col>


            <Col md={6} sm={12} xs={24}>
            <div className="form-lang">
              <Form.Item
                label={"Əvvəlki bölmə"}
                name="typesId"
                validateTrigger="onChange"
                //   rules={[whiteSpace(t("inputError"))]}
              >
                <Select
                >
                  {types.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}

                </Select>
              </Form.Item>
              </div>
            </Col>


            <Col md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("Büdcə")}
                  name="budget"
                  validateTrigger="onChange"
                >
                  <InputNumber defaultValue={0} />
                </Form.Item>
                <div className="input-lang">azn</div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("İstifadəçilərin sayı")}
                  name="userCount"
                  validateTrigger="onChange"
                >
                  <InputNumber />
                </Form.Item>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("Web səhifə")}
                  name="webPageUrl"
                  validateTrigger="onChange"
                >
                  <Input size={"large"} />
                </Form.Item>
              </div>
            </Col>

            {techArray.map((elem, index) => (
              <Col className="flex  flex-align-center " key={index}>
                <div style={{ display: "none" }}>
                  <Form.Item
                    label={"Texnologiyanın kateqoriyası"}
                    name={["productCategories", elem, "id"]}
                    validateTrigger="onChange"
                  >
                    <Input />
                  </Form.Item>
                </div>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    label={"Texnologiyanın kateqoriyası"}
                    name={["productCategories", elem, "technologyCategoryId"]}
                    validateTrigger="onChange"
                  >
                    <Select onChange={(value) => getSubcategories(value, elem)}>
                      {categories.map((p, index) => {
                        return (
                          <Option key={index} value={p.id}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    label={"Alt kateqoriyası"}
                    name={[
                      "productCategories",
                      elem,
                      "technologySubcategoryId",
                    ]}
                    validateTrigger="onChange"
                  >
                    <Select
                      onChange={(value) => getCategoriesType(value, elem)}
                      disabled={subDisable}
                    >
                      {subCategories?.map((item) => {
                        if (item.index === elem) {
                          return item.array.map((p, index) => {
                            return (
                              <Option key={index} value={p.id}>
                                {p.name}
                              </Option>
                            );
                          });
                        }
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    label={"Növü"}
                    name={["productCategories", elem, "technologyTypeId"]}
                    validateTrigger="onChange"
                  >
                    <Select disabled={typeDisable}>
                      {categoriesType.map((item) => {
                        if (item.index === elem) {
                          return item.array.map((p, index) => {
                            return (
                              <Option key={index} value={p.id}>
                                {p.name}
                              </Option>
                            );
                          });
                        }
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <div>
                  <Button
                    onClick={() => setTechArray([...techArray, techArray[techArray.length - 1] + 1])}
                    className={"ml-15"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => {
                      handleDeleteRow(elem);
                    }}
                    className={"ml-15"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<MinusOutlined />}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <div className="flex flex-end">
            <Button onClick={cancelEditing}>{t("cancel")}</Button>

            <Button type="primary" className="ml-10" htmlType="submit">
              {t("save")}
            </Button>
          </div>
        </Form>
      </div>

      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Table
            loading={spin}
            size="small"
            className="bg-whit animated fadeInLeft mt-15"
            columns={columns}
            dataSource={convertColumns(positions, cols)}
            pagination={{
              pageSize: 10,
              current_page: 1,
              total: positions.length,
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default connect(null, { notify })(Product);
