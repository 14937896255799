export const whiteSpace = (message) => {
    return {
        required: true,
        message,
        whitespace: true
    }
}
export const noWhitespace = (message) => {
    return {
        required: true,
        message,
    }
}

export const number= (message) => {
    return {
        required: true,
        number:true,
        message,
    }
}
