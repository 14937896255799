import React, { useEffect, useState, useRef } from "react";
import {

  Card,
  Button,
  Form,
  Input,
  Select,
} from "antd";

import { notify } from "../../redux/actions";
import aictia from "../../const/api";
import { useTranslation } from "react-i18next";
import { noWhitespace } from "../../utils/rules";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";

function TechnologySubcategory(props) {
  const mainUrl = "TechnologySubcategory";
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const nameInput = useRef();
  const { Option } = Select;
  const [categories, setCategoires] = useState([]);

  const setEditingObject = async (i) => {
    await aictia.get(mainUrl + `/${props.id}`).then((res) => {

      let obj = {};
      obj[`name`] = res.data.name;
      obj[`technologyCategoryId`] = res?.data?.technologyCategoryId;

      form.setFieldsValue(obj);
    });
  };

  const cancelEditing = () => {
    props.setEditing(null);
    form.resetFields();
  };

  const savePosition = async (values) => {
    let obj = {
      ...values
    }

    if (!props.id) {
      await aictia
        .post(mainUrl, obj)
        .then((res) => {
          notify("", true);
          props.getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      obj["id"] = props.id
      await aictia
        .put(`${mainUrl}/${props.id}`, obj)
        .then((res) => {
          notify("", true);
          props.getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  const getCategories = async () => {
    await aictia.get("Technology").then((res) => {
      setCategoires(res?.data);
    });
  };
  useEffect(() => {
    setEditingObject();
    getCategories();
  }, [props.id]);
  return (
    <div>
      {" "}
      <Card title={"Kateqoriya əlavə et"} className={"animated fadeInRight administrator"}>
        <Form layout="vertical" onFinish={savePosition} form={form}>
          <div className="form-lang mb-20">
            <FloatLabel>

              <Form.Item
                name={"technologyCategoryId"}
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
                className="taskkk"
              >
                <Select id="technologyCategoryId">
                  {categories.map((item, i) => {
                    return (
                      <Option key={i} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <label htmlFor="technologyCategoryId">{t('Texnologiyaların kateqoriyası')}</label>

            </FloatLabel>

            <FloatLabel>

              <Form.Item
                name={"name"}
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
                className="mb-5"
              >
                <InputText ref={nameInput} className="w-full" id="name" />

              </Form.Item>
              <label htmlFor="name">{t('Texnologiyaların alt kateqoriyasının adı')}</label>
            </FloatLabel>

          </div>

          <div className="flex  flex-between mt-15">
            <Button onClick={cancelEditing}>{t("cancel")}</Button>
            <Button htmlType="submit">{t("save")}</Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default TechnologySubcategory;
