import React, { useState, useEffect } from 'react';
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  AuditOutlined,
  UsergroupAddOutlined,
  BankOutlined,
  SwitcherOutlined,
  SnippetsOutlined,
  LaptopOutlined,
  GatewayOutlined,
  ContainerOutlined,
  DiffOutlined

} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';


function CollapseMenu() {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const rootSubmenuKey = ["11", "21", "31", "41", "51", "61", "71", "81", "91", "101"];
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState(location.pathname)


  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);




  const onOpenChange = (openKeysList) => {
    console.log(openKeysList);
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKey.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };



  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      selectedKeys={[selectedKey]}
      onClick={(e) => setSelectedKey(e.key)}
      onOpenChange={onOpenChange}
      className="menu-ul"
    >
      <Menu.Item key="/activity">
        <Link to={`/activity`}>
          <span>
            <AuditOutlined />
            <span>{t('fieldOfActivity')}</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/typeofactivity">
        <Link to={`/typeofactivity`}>
          <span>
            <ContainerOutlined />
            <span>{t('typeOfActivity')}</span>
          </span>
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="91">
          <Link to={/admin/position}>
          <span>
          <UserSwitchOutlined />
            <span>Vəzifə</span>
          </span>
          </Link>
        </Menu.Item> */}
      <Menu.Item key="/admin/users">
        <Link to={`/admin/users`}>
          <span>
            <UsergroupAddOutlined />
            <span>İstifadəçilər</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/stateorprivate">
        <Link to={`/admin/stateorprivate`}>
          <span>
            <BankOutlined />
            <span >{t("statePrivate")}</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/packetofaictia">
        <Link to={`/packetofaictia`}>
          <span>
            <DiffOutlined />
            <span>{t('membershipPackage')}</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/fieldOfUse">
        <Link to={`/admin/fieldOfUse`}>
          <span>
            <SnippetsOutlined />
            <span >İstifadə sahəsi</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/types">
        <Link to={`/admin/types`}>
          <span>
            <SwitcherOutlined />
            <span >Növlər</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/technologies">
        <Link to={`/admin/technologies`}>
          <span>
            <LaptopOutlined />
            <span >Texnologiyalar</span>
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/admin/sector">
        <Link to={`/admin/sector`}>
          <span>
            <GatewayOutlined />
            <span >Sektor</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/logs">
        <Link to={`/admin/logs`}>
          <span>
            <ContainerOutlined />
            <span >Loqlar</span>
          </span>
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="/admin/history`">
          <Link to={/admin/history}>
          <span>
          <ContainerOutlined />
            <span >Tarixçələr</span>
          </span>
          </Link>
        </Menu.Item> */}


    </Menu>
  )
}

export default CollapseMenu
