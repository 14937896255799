import React, { useState, useEffect, useRef} from "react";
import { Row, Col, Spin, Button } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { aictia } from "../../../const/api";
import { useTranslation } from "react-i18next";

function Index() {
  const [data, setData] = useState([]);
  const [activityPie, setActivityPie] = useState({ labels: [], series: [] });

  const [companyPie, setCompanyPie] = useState({ labels: [], series: [] });
  const [activtyTypeSeries, setActivityTypeSeries] = useState([]);
  const [activtyCompanySeries, setCompanyTypeSeries] = useState([]);
  const [componyGrafikType, setComponyGrafikType] = useState(true);
  const [capitalGrafikType, setCapitalGrafikType] = useState(true);

  const [activityYears, setActivityYears] = useState([]);
  const [companyActivityYears, setCompanyActivityYears] = useState([]);
  const [spin, setSpin] = useState(false);
  const [typeOfCompanyX, setTypeOfCompanyX] = useState([]);
  const [typeOfCompanySeries, setTypeOfCompanySeries] = useState([]);
  const [typeOfCompanyCapital, setTypeOfCompanyCapital] = useState([]);

  const { t } = useTranslation();
  const getData = () => {
    setSpin(true);
    aictia.get("Dashboard").then((res) => {
      setSpin(false);
      setData(res.data);
      const activityFields = res.data?.dashboard_GroupedData.groupOfActivity

      const companyType = res.data?.dashboard_GroupedData.groupOfTypeOfCompany

      const typeOfCompanyCapital = res.data?.dashboard_GroupOfTypeOfActivity.authorizedCapitalByTypeOfActivity
      const typeOfCompany = res.data?.dashboard_GroupOfTypeOfActivity.countOfCompamyByTypeOfActivity

      if (activityFields) {
        const activityLabels = activityFields?.map((elem) => elem.name);
        const activitySeries = activityFields?.map((elem) => elem.count);
        setActivityPie({ activityLabels, activitySeries });
      }


      if (companyType) {
        const companyLabels = companyType?.map((elem) => {
          const name = elem.name === "İctimai birlik" ? "İ/B" : elem.name; // 'İctimai birlik' ise 'IB' yap
          return `${name}`;
        });

        const companySeries = companyType?.map((elem) => elem.count);
        setCompanyPie({ companyLabels, companySeries });
      }


      const allTypeOfCompany = typeOfCompanyCapital?.map((item) => item.name);
      const allTypeOfCompanySeries = typeOfCompanyCapital?.map((item) => item.sumAuthorizedCapital);
      const allTypeOfCompanyCount = typeOfCompany?.map((item) => item.count);
      setTypeOfCompanyX(allTypeOfCompany);
      setTypeOfCompanyCapital(allTypeOfCompanySeries);
      setTypeOfCompanySeries(allTypeOfCompanyCount);
    });
  };
  const activitySumFunc = () => {
    const activityType = data?.statisticByYears?.authorizedCapitalByYears
    const allYears = [
      ...new Set(activityType?.map((item) => item.years)),
    ];
    const yearsAfter2015 = capitalGrafikType ? allYears
      .filter((year) => parseInt(year) >= 2015).reverse() : allYears
        .filter((year) => parseInt(year) >= 2015)
    setActivityYears(yearsAfter2015);

    let cumulativeSum1 = 0;
    const cumulativeData1 = activityType?.map(item => {

      cumulativeSum1 += item.sumAuthorizedCapital; // Birikimli toplama mevcut elemanın count'unu ekle
      return { ...item, cumulativeCount: cumulativeSum1 }; // Yeni bir nesne döndür
    });
    const names = [...new Set(cumulativeData1?.filter(item => parseInt(item.years) >= 2015).map((item) => capitalGrafikType ? item.cumulativeCount : item.sumAuthorizedCapital))];
    const reverseName = capitalGrafikType ? names.reverse() : names;
    setActivityTypeSeries(reverseName);


  }
  const companySumFunc = () => {
    const companySum = data?.statisticByYears?.companyCountByYears

    const allYears = [
      ...new Set(companySum?.map((item) => item.years)),
    ];
    const yearsAfter2015Company = componyGrafikType ? allYears
      .filter((year) => parseInt(year) >= 2015).reverse() : allYears
        .filter((year) => parseInt(year) >= 2015)
    setCompanyActivityYears(yearsAfter2015Company);

    let cumulativeSum = 0; // Birikimli toplamı tutacak değişken
    // Birikimli toplamı tutacak değişken
    const cumulativeData = companySum?.map(item => {
      cumulativeSum += item.count; // Birikimli toplama mevcut elemanın count'unu ekle
      return { ...item, cumulativeCount: cumulativeSum }; // Yeni bir nesne döndür
    });

    const names1 = [...new Set(cumulativeData?.filter(item => parseInt(item.years) >= 2015).map((item) => componyGrafikType ? item.cumulativeCount : item.count))];
    const reverseName1 = componyGrafikType ? names1.reverse() : names1;

    setCompanyTypeSeries(reverseName1);


  }


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log(data);

    if (data) {
      activitySumFunc();
      companySumFunc();
    }

  }, [data, componyGrafikType, capitalGrafikType]);


  const activityField = {
    options: {
      labels: activityPie.activityLabels || [],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -30,
            enabled: false, // Yüzdelik değerleri tamamen kaldır
          },
        },
      },
      dataLabels: {
        enabled: false, // Ek olarak global dataLabels'i kapat
      },
      chart: {
        background: 'transparent', // Şeffaf arka plan
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 50,
        marginRight: 50,
      },
      stroke: {
        show: false, // Çevre kenar çizgilerini kaldır
      },
      legend: {
        position: 'right', // Legend'leri grafiğin altına taşır
        fontSize: '12px',
        offsetY: 10,
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + " (" + opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(2) + "%) ";
        }

      },
    },
    series: activityPie.activitySeries || [],
  };

  const typeOfCompany = {
    options: {
      labels: companyPie.companyLabels || [],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -30,
            enabled: false, // Yüzdelik değerleri tamamen kaldır
          },
        },
      },
      dataLabels: {
        enabled: false, // Ek olarak global dataLabels'i kapat
      },
      chart: {
        background: 'transparent', // Şeffaf arka plan
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 50,
        marginRight: 50,
      },
      stroke: {
        show: false, // Çevre kenar çizgilerini kaldır
      },
      legend: {
        position: 'right', // Legend'leri grafiğin altına taşır
        fontSize: '12px',
        offsetY: 10,
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + " (" + opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(1) + "%)";
        }
      },
    },
    series: companyPie.companySeries || [],
  };
  const responsive = window.innerWidth < 2000 && window.innerWidth > 1700 ? true : false;
  const state = {
    options: {
      chart: {
        id: "basic-bar",
        background: 'transparent', // Şeffaf arka plan
        toolbar: {
          show: false, // Toolbar'i kaldır
        },
      },
      plotOptions: {
        bar: {
          horizontal: true, // Çubukları yatay yap
        },
      },
      xaxis: {
        categories: activityYears,
        labels: {
          formatter: function (val) {

            // Sayıları kısaltarak göster
            if (capitalGrafikType && val >= 1000000) {
              return (val / 1000000) + "M"; // Milyonlar için
            } else if (capitalGrafikType && val >= 1000) {
              return (val / 1000) + "K"; // Binler için
            }
            return val; // Küçük sayılar
          },
        },

      },
      yaxis: {
        labels: {
          formatter: function (val) {

            // Sayıları kısaltarak göster
            if (!capitalGrafikType && val >= 1000000) {
              return (val / 1000000) + "M"; // Milyonlar için
            } else if (!capitalGrafikType && val >= 1000) {
              return (val / 1000) + "K"; // Binler için
            }
            return val; // Küçük sayılar
          },
        },
      },

      dataLabels: {
        enabled: false, // Barların üzerindeki yazıları gizle
      },
    },
    series: [
      {
        name: "Ümumi nizamnamə kapitalı",
        data: activtyTypeSeries,
      }
    ]
  };
  const state1 = {
    options: {
      chart: {
        id: "basic-bar",
        background: 'transparent', // Şeffaf arka plan
        toolbar: {
          show: false, // Toolbar'i kaldır
        },
        width: '100%'
      },
      plotOptions: {
        bar: {
          horizontal: true, // Çubukları yatay yap
        },
      },
      xaxis: {
        categories: companyActivityYears,

      },


      dataLabels: {
        enabled: false, // Barların üzerindeki yazıları gizle
      },
    },
    series: [
      {
        name: "Şirkət sayı",
        data: activtyCompanySeries,
      }
    ]
  };
  console.log(typeOfCompanyX);

  const state2 = {
    options: {
      chart: {
        id: "basic-bar",
        background: 'transparent', // Şeffaf arka plan
        toolbar: {
          show: false, // Toolbar'i kaldır
        },
      },
      xaxis: {
        categories: typeOfCompanyX,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            console.log(val);

            // Sayıları kısaltarak göster
            if (val >= 1000000) {
              return (val / 1000000) + "M"; // Milyonlar için
            } else if (val >= 1000) {
              return (val / 1000) + "K"; // Binler için
            }
            return val; // Küçük sayılar
          },
        },
      },

      dataLabels: {
        enabled: false, // Barların üzerindeki yazıları gizle
      },
      grid: {
        padding: {
          bottom: 70,
        },
      },
      responsive: [
        {
          breakpoint: 1550,
          options: {
            xaxis: {
              labels: {
                rotate: -45,
                style: {
                  fontSize: "10px",
                },

              },
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Nizamnamə kapitalı",
        data: typeOfCompanyCapital
      }
    ]
  };
  const state3 = {
    options: {
      chart: {
        id: "basic-bar",
        background: 'transparent', // Şeffaf arka plan
        toolbar: {
          show: false, // Toolbar'i kaldır
        },
      },
      xaxis: {
        categories: typeOfCompanyX,

      },
      yaxis: {
        labels: {
          formatter: function (val) {


            // Sayıları kısaltarak göster
            if (val >= 1000000) {
              return (val / 1000000) + "M"; // Milyonlar için
            } else if (val >= 1000) {
              return (val / 1000) + "K"; // Binler için
            }
            return val; // Küçük sayılar
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          bottom: 70,
        },
      },
      responsive: [
        {
          breakpoint: 1550,
          options: {
            xaxis: {
              labels: {
                rotate: -45,
                style: {
                  fontSize: "10px",
                },

              },
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Şirkət sayı",
        data: typeOfCompanySeries
      }
    ]
  };


  console.log(componyGrafikType);


  return (
    <div className="dashboard">
      <Col xs={24}>
        <div className="border animated fadeInDown p-2 mt-0 bg-white">
          <BarChartOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">Statistika</span>
        </div>
      </Col>
      {spin ? (
        <div className="flex flex-align-center justify-center mt-15">
          <Spin />
        </div>
      ) : (
        <>
          <Col>

            <Row gutter={16}>
              <Col xs={24} md={12} lg={6}>
                <div className="company">
                  <p>Şirkətlər</p>

                  <CountUp end={data?.generalIndicators?.countOfCompanies} separator=" " />
                </div>
              </Col>
              <Col xs={24} md={12} lg={6}>

                <div className="company">
                  <p>Bağlanmış şirkətlər</p>

                  <CountUp end={data?.generalIndicators?.countOfDeactiveCompanies} separator=" " />
                </div>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <div className="company">
                  <p>Nizamnamə kapitalı</p>
                  <CountUp
                    end={data?.generalIndicators?.sumOfAuthorizedCapitalActiveCompanies}
                    separator=" "
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <div className="company">
                  <p>Əməkdaşlar</p>
                  <CountUp
                    end={data?.generalIndicators?.sumOfEmployeeActiveCompanies}
                    separator=" "
                  />
                </div>
              </Col>

            </Row>
            <Row className="flex flex-between" gutter={16} style={{ alignItems: 'center' }}>
              <Col xs={24} lg={12} className="flex justify-center" >
                <div className="">
                  <h2 className="title">Fəaliyyət sahəsinə görə</h2>
                  <div className="mixed-chart">
                    <Chart
                      options={activityField.options}
                      series={activityField.series}
                      type="pie"
                      width={responsive ? "600" : "500"}
                      height={responsive ? "600" : "500"}
                    />
                  </div>
                </div>
              </Col>


              <Col xs={24} lg={12} className="flex justify-center" >
                <div className="">
                  <h2 className="title"> {t('businessEntity')}</h2>
                  <div className="mixed-chart">
                    <Chart
                      options={typeOfCompany.options}
                      series={typeOfCompany.series}
                      type="pie"
                      width={responsive ? "600" : "500"}
                      height={responsive ? "600" : "500"}
                    />
                  </div>
                </div>
              </Col>

            </Row>
          </Col>

          <Row gutter={16} className="mt-50">
            <Col className=" chart-div" xs={24} lg={12}>
              <div className="chart">
                <div className="flex flex-align-center mb-20">
                  <div className="flex dir-column gap-4 mr-20">
                    <Button style={{ backgroundColor: componyGrafikType ? '#091a3f' : 'rgb(116, 116, 117)', color: '#fff' }} onClick={() => setComponyGrafikType(true)}> Şirkətlərin sayı</Button>

                  </div>
                  <Button style={{ backgroundColor: !componyGrafikType ? '#091a3f' : 'rgb(116, 116, 117)', color: '#fff' }} onClick={() => setComponyGrafikType(false)}> Şirkətlərin Dinamikası</Button>
                  {/* <h2 className="title">{ componyGrafikType ? t('Şirkətlərin sayı') : 'Şirkətlərin Dinamikası'}</h2> */}
                </div>

                <Chart
                  options={state1.options}
                  key={componyGrafikType}
                  series={state1.series}
                  type={componyGrafikType ? "bar" : "line"}
                  height={550}
                />
              </div>
            </Col>
            <Col className=" chart-div" xs={24} lg={12}>
              <div className="chart">
                <div className="flex flex-align-center mb-20">
                  <div className="flex dir-column gap-4 mr-20">
                    <Button style={{ backgroundColor: capitalGrafikType ? '#091a3f' : 'rgb(116, 116, 117)', color: '#fff' }} onClick={() => setCapitalGrafikType(true)}> Nizamnamə kapitalı</Button>

                  </div>
                  <Button style={{ backgroundColor: !capitalGrafikType ? '#091a3f' : 'rgb(116, 116, 117)', color: '#fff' }} onClick={() => setCapitalGrafikType(false)}> Nizamnamə kapitalın Dinamikası</Button>
                  {/* <h2 className="title">{ capitalGrafikType ? t('Nizamnamə kapitalı') : 'Nizamnamə kapitalın Dinamikası'}</h2> */}
                </div>


                <Chart
                  options={state.options}
                  key={capitalGrafikType}
                  series={state.series}
                  type={capitalGrafikType ? "bar" : "line"}
                  height={550}
                />
              </div>
            </Col>
          </Row>

          <Row className="flex dir-column flex-align-center mt-50 x-axisBar" gutter={16}>
            <Col className="w-100" xs={24} sm={24} md={24} lg={23} xl={23} >
              <Col className="flex flex-align-center flex-between mb-20 w-100">
                <div className="flex dir-column gap-4 butonnn">
                  <Button style={{ backgroundColor: '#091a3f', color: '#fff', cursor: 'default' }} > Şirkətlərin sayı</Button>

                </div>
                <h1>Fəaliyyət növünə görə şirkətlərin sayı</h1>
                {/* <h2 className="title">{ componyGrafikType ? t('Şirkətlərin sayı') : 'Şirkətlərin Dinamikası'}</h2> */}
              </Col>
              <Col className="chart">
                <Chart 
                  options={state3.options}
                  series={state3.series}
                  type={"bar"}
                  height={550}
                />
              </Col>
            </Col>
            <Col className="mt-40 w-100" xs={24} sm={24} md={24} lg={23} xl={23}>
              <Col className="flex flex-align-center flex-between mb-20 w-100">
                <div className="flex dir-column gap-4 butonnn">
                  <Button style={{ backgroundColor: '#091a3f', color: '#fff', cursor: 'default' }} > Nizamnamə kapitalı</Button>

                </div>
                <h1>Fəaliyyət növünə görə nizamnamə kapitalı</h1>
                {/* <h2 className="title">{ componyGrafikType ? t('Şirkətlərin sayı') : 'Şirkətlərin Dinamikası'}</h2> */}
              </Col>
              <Col className="chart">
                <Chart 
                  options={state2.options}
                  series={state2.series}
                  height={550}
                  type="bar"
                />
              </Col>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Index;
 