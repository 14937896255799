import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  FileDoneOutlined,
  BarChartOutlined,
  GatewayOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const MenuList = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(location.pathname)
  const rootSubmenuKeys = ["11", "21", "31",];



  useEffect(() => {
    const currentPath = location.pathname;
    setSelectedKey(location.pathname);
    if (role !== 'Organization') {
      if (currentPath === '/') {
        setSelectedKey('/register')
      }
    }
    else {
      if (currentPath === '/') {
        setSelectedKey('/organization')
      }
    }
  }, [location.pathname]);

  const onOpenChange = (openKeysList) => {
    console.log(openKeysList);
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };
  const role = localStorage.getItem("role");

  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      selectedKeys={[selectedKey]}
      onOpenChange={onOpenChange}
      onClick={(e) => setSelectedKey(e.key)}
      className="menu-ul"
    >
      {role !== 'Organization' &&
        <Menu.Item key="/register">
          <Link to={`/register`}>
            <span>
              <FileDoneOutlined />
              <span >Reyestr</span>
            </span>

          </Link>
        </Menu.Item>}
      {role !== 'Organization' &&
        <Menu.Item key="/dashboard">
          <Link to={`/dashboard`}>
            <span>
              <BarChartOutlined />
              <span >Statistika</span>
            </span>

          </Link>
        </Menu.Item>}

      {
        role === 'Organization' &&
        <Menu.Item key="/organization">
          <Link to={`/organization`}>
            <span>
              <GatewayOutlined />
              <span >{t('myCabinet')}</span>
            </span>

          </Link>
        </Menu.Item>
      }
      {role == 'Organization' &&
        <Menu.Item key="/organization/dashboard">
          <Link to={`/organization/dashboard`}>
            <span>
              <BarChartOutlined />
              <span >Statistika</span>
            </span>

          </Link>
        </Menu.Item>}

    </Menu>
  );
};

const mapStateToProps = ({ user, stock }) => {
  return {
    // perms: user.data.userPermissions, stock 
  };
};

export default connect(mapStateToProps, {})(MenuList);


