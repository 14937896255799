
import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Tooltip,
  Row,
  Col,
  Table,
  Spin,
  Select,
  Form,
  Popconfirm,
  Input,
} from "antd";
import { convertColumns } from "../../../utils/columnconverter";
import {
  DeleteFilled,
  EditFilled,
  EyeOutlined,
  ClearOutlined,
  SettingOutlined,
  PlusOutlined,
  HistoryOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { notify } from "../../../redux/actions";
import { connect } from "react-redux";
import { aictia } from "../../../const/api";
import { useTranslation } from "react-i18next";
import excel from "../../../assets/img/excel.jpeg";

const { Option } = Select;
function IndividualMembers() {
  let mainUrl = "ReyestrOfCompany";
  const getSavedPage = () => Number(sessionStorage.getItem("currentPage")) || 1;

  const [form] = Form.useForm();
  const [individualPostList, setIndividualPostList] = useState([]);
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [input, setInput] = useState(null);
  const [page, setPage] = useState(getSavedPage);
  const [filter, setFilter] = useState({});
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [typeOfActivity, setTypeOfActivity] = useState([
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
  ]);
  const [packet, setPacket] = useState([]);
  const [activity, setActivity] = useState([]);
  const [columns, setColumns] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const { t } = useTranslation();
  const role = localStorage.getItem("role");
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    {
      key: "nameOfCompany",
      value: t('nameOfEntity'),
      con: true,
    },
    {
      key: "officialCEOoftheCompany",
      value: t('officialRepresentative'),
      con: true,
    },
    { key: "typeOfActivity", value: t('type'), con: false },
    { key: "activity", value: t('field'), con: false },

    { key: "packetOfAictia", value: "Paket", con: false },
  ];
  const individualColumns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
      className: "tableindex",
    },
    {
      title: t('nameOfEntity'),
      dataIndex: "nameOfCompany",
      key: "2",
      sorter: (a, b) => a.nameOfCompany.localeCompare(b.nameOfCompany),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t('officialRepresentative'),
      dataIndex: "officialCEOoftheCompany",
      key: "3",
    },
    {
      title: t('tin'),
      dataIndex: "tin",
      key: "7",
      width: 130,
    },
    {
      title: t('type'),
      dataIndex: "typeOfActivityCompanyDtos",
      key: "6",
      render: (items) => {
        if (!items || !Array.isArray(items)) return null; // Əgər dəyər yoxdursa, boş qaytar
        const names = items.map((p) => p.name)
        const mainNames = items
          .filter((elem) => elem.isMain) // `isMain === true` olanları seç
          .map((p) => p.name)


        const filteredNames = items
          .filter((elem) => !elem.isMain) // `isMain === false` olanları seç
          .map((p) => p.name)


        return names.length > 0 ?
          <Tooltip title={filteredNames.join(", ")} placement="rightTop">
            {mainNames}{filteredNames.length > 0 ? "..." : ""}
          </Tooltip> : "-"; // Boş olarsa "-", əks halda siyahı göstər
      },

    },
    // {
    //   title: t('Sahə'),
    //   dataIndex: "activityCompanyDtos",
    //   key: "6",
    //   render: (items) => {
    //     if (!items || !Array.isArray(items)) return null; // Əgər dəyər yoxdursa, boş qaytar
    // const names = items.map((p) => p.name)
    // const mainNames = items
    //   .filter((elem) => elem.isMain) // `isMain === true` olanları seç
    //   .map((p) => p.name)


    //     const filteredNames = items
    //   .filter((elem) => !elem.isMain) // `isMain === false` olanları seç
    //   .map((p) => p.name)


    //     return names.length > 0 ?  
    //     <Tooltip  title={filteredNames.join(", ")} placement="rightTop">
    //     {mainNames}{filteredNames.length > 0 ? "..." : ""}
    //   </Tooltip>  : "-"; // Boş olarsa "-", əks halda siyahı göstər
    //   },
    // }
    ,
    {
      title: t('Sayt'),
      dataIndex: "officialWebSite",
      key: "8",
      width: 160,
      render: (i) => {
        return <p>{i?.replace("https://", "")}</p>;
      }
    },
    // {
    //   title: "Şirkətin nömrəsi",
    //   dataIndex: "phoneOfCompany",
    //   key: "9",
    //   render: (i) => {
    //     return <span>{i[0]?.value}</span>;
    //   },
    // },
    // {
    //   title: "Vöen",
    //   dataIndex: "tin",
    //   key: "10",
    // },
    // {
    //   title: "Paket",
    //   dataIndex: "packetOfAictia",
    //   key: "10",
    //   render: (i) => {
    //     return <span>{i?.name ? i.name : "Paket seçilməyib"}</span>;
    //   },
    // },
    {
      title: "",
      dataIndex: "id",
      key: "",
      render: (i, index) => {
        return (
          <div className="flex flex-end">
            {
              role === 'Admin' && (
                <>
                  <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                    <Link
                      to={{
                        pathname: `/register/edit/${i}`,
                        state: { page: page }
                      }}

                    >
                      <Button className="border-none" type="text" shape="circle">
                        <EditFilled />
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    className="ml-5"
                    title={t("detailed")}
                    placement="topRight"
                  >
                    <Link
                      to={{
                        pathname: `/register/view/${i}`,
                        state: { page: page }
                      }}
                    >
                      <Button className="border-none" type="text" shape="circle">
                        <EyeOutlined />
                      </Button>
                    </Link>
                  </Tooltip>
                  <Popconfirm
                    placement="topRight"
                    title={t("areYouSure")}
                    onConfirm={() => deletePost(i)}
                    okText={t("yes")}
                    cancelText={t("no")}
                  >
                    <Tooltip className="ml-5" title={t("delete")}>
                      <Button className="border-none" type="text" shape="circle">
                        <DeleteFilled />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </>
              )
            }
            <Tooltip
              className="ml-5"
              title={t("Tarixçəyə bax")}
              placement="topRight"
            >
              <Link
                to={{
                  pathname: `/register/history/${i}`,
                  state: { page: page }


                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <HistoryOutlined />
                </Button>
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const deletePost = async (i) => {
    if (i === 0 || i) {
      await aictia
        .delete(`${mainUrl}/${i}`)
        .then((res) => {
          getRegister();
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };


  const getRegister = async (paramss) => {
    setSpin(true);
    console.log(page);
    // API sorğusu üçün parametrlər
    const params = {
      PerDataCount: (page - 1) * pageSize,
      GetDataCount: pageSize,
    };
    console.log(params);
    // Əlavə query parametrlərini hazırlayırıq
    const queryParts = [];

    if (paramss?.TypeOfActivityIds?.length) {
      queryParts.push(paramss.TypeOfActivityIds.map((p) => `TypeOfActivityIds=${p}`).join("&"));
    }

    if (paramss?.ActivitiyIds?.length) {
      queryParts.push(paramss.ActivitiyIds.map((p) => `ActivitiyIds=${p}`).join("&"));
    }
    if (paramss?.ActivityStatus === false || paramss?.ActivityStatus === true) {
      queryParts.push(`ActivityStatus=${paramss.ActivityStatus}`);
    }
    if (paramss?.QueryParam) {
      queryParts.push(`QueryParam=${paramss.QueryParam}`);
    }


    // Query stringi formalaşdırırıq
    const queryString = queryParts.length ? `?${queryParts.join("&")}` : "";

    // API çağırışı
    await aictia.get(`ReyestrOfCompany${queryString}`, { params })
      .then((res) => {
        if (res.data) {
          setSpin(false);
          setIndividualPostList(
            res.data.data.map((d, index) => ({
              ...d,
              key: index + 1,
              index,
              tableIndex: res.data.count - index - (page - 1) * pageSize,
            }))
          );
          setCount(res.data.count);
        }
      })
      .catch(() => setSpin(false)); // Əgər səhv olarsa, spin-i söndür
  };


  const onSearch = (values) => {
    console.log(values);
    setPage(1)

    const params = {
      ...values,
      QueryParam: values?.QueryParam,
      currentPage: 1
    }
    console.log(params);

    setFilter(params);
    getRegister(params)
  };
  const clearFilter = () => {
    form.resetFields();
    setPage(1);
    setInput(null);
    setFilter({});
    getRegister();
  };
  const getTypeOfActivity = () => {
    aictia.get("TypeOfActivity").then((res) => {
      setTypeOfActivity(res.data);
    });
  };
  const getActivity = () => {
    aictia.get("Activity").then((res) => {
      setActivity(res.data);
    });
  };
  const getAictiaPacket = () => {
    aictia.get("PacketOfAictia").then((res) => {
      setPacket(res.data);
    });
  };
  const columnsData = (e) => {
    setColumns(
      individualColumns.filter((p, index) => {
        if (e.includes(p.dataIndex)) {
          return p;
        }
        if (e.length === 0) {
          return individualColumns;
        }
        if (p.dataIndex === "tableIndex") {
          return p;
        }
        if (p.dataIndex === "id") {
          return p;
        }
      })
    );
    setTrigger(trigger + 1);
  };
  const chooseInput = () => {
    switch (input) {
      case 1:
        return (
          <Col md={4} sm={12} xs={24}>
            <Form.Item
              name="nameOfCompany"
              className={"mb-0"}
            // rules={[whiteSpace(t("inputError"))]}
            >
              <Input placeholder={t("search")} size={"large"} />
            </Form.Item>
          </Col>
        );
      case 2:
        return (
          <Col md={4} sm={12} xs={24}>
            <Form.Item
              name="officialCEOoftheCompany"
              className={"mb-0"}
            // rules={[whiteSpace(t("inputError"))]}
            >
              <Input
                placeholder={t('officialRepresentative')}
                size={"large"}
              />
            </Form.Item>
          </Col>
        );

      case 3:
        return (
          <Col md={4} sm={12} xs={24}>
            <Form.Item
              name="tin"
              className={"mb-0"}
            // rules={[whiteSpace(t("inputError"))]}
            >
              <Input
                placeholder={t('tin')}
                size={"large"}
              />
            </Form.Item>
          </Col>
        );

      default:
        return null;
    }
  };


  useEffect(() => {
    getTypeOfActivity();
    getActivity();
    getAictiaPacket();
  }, [t]);

  useEffect(() => {
    getRegister(filter);
  }, [page, pageSize, t]);


  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading p-2 flex mt-0 bg-white">
            <div className="page-name">
              <Form onFinish={onSearch} form={form}>
                <div className="commontask bg-white ">
                  <Row className={"mt-5"} gutter={[8, 8]}>
                    <Col xs={24} sm={12} md={5} lg={6} xl={5}>
                      <Form.Item
                        name={"QueryParam"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >

                        <Input placeholder={t("search")} size={"large"} />
                      </Form.Item>
                    </Col>
                    <Col md={5} sm={12} xs={24} lg={6} xl={5}>
                      <Form.Item
                        name={"TypeOfActivityIds"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select placeholder={t('typeOfActivity')} maxTagCount={1} mode="multiple"
                          maxTagPlaceholder={(omittedValues) => (
                            <span>+{omittedValues.length}</span>
                          )}>
                          {typeOfActivity.map((p, index) => {
                            return (
                              <Option key={index} value={p.id}>
                                {p.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={5} sm={12} xs={24} lg={6} xl={5}>
                      <Form.Item
                        name={"ActivitiyIds"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select placeholder={t('fieldOfActivity')} maxTagCount={1} mode="multiple"
                          maxTagPlaceholder={(omittedValues) => (
                            <span>+{omittedValues.length}</span>
                          )}>
                          {activity.map((p, index) => {
                            return (
                              <Option key={index} value={p.id}>
                                {p.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={5} sm={12} xs={24} lg={6} xl={5}>
                      <Form.Item
                        name={"ActivityStatus"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select placeholder={t('Status')} size="medium">
                          <Option value={false}>{t('Bağlanmış şirkətlər')}</Option>
                          <Option value={true}>{t('Fəaliyyət göstərən şirkətlər')}</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={3} sm={12} xs={24} lg={3} xl={2}>
                      <Form.Item name={" "}>
                        <Button
                          type="primary"
                          size={"large"}
                          className="w-100 f-13"
                          htmlType="submit"
                        >
                          {t("search")}
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col md={3} sm={12} xs={24} lg={3} xl={2}>
                      <Form.Item name={" "}>
                        <Button
                          type="primary"
                          size={"large"}
                          onClick={() => {
                            clearFilter();
                          }}
                          className="w-100"
                          htmlType="submit"
                        >
                          <ClearOutlined />
                        </Button>
                      </Form.Item>
                    </Col>


                    {/* <div className="register-head">

                      <Col md={3} sm={12} xs={24} lg={3} xl={8}>
                        <div className="register">
                          <label className="labelPlace">
                            {" "}
                            <SettingOutlined />
                          </label>

                          <Select
                            dropdownMatchSelectWidth={false}
                            showSearch={false}
                            mode="multiple"
                            onChange={(e) => columnsData(e)}
                          >
                            {individualColumns.map((p, index) => {
                              return (
                                p.dataIndex !== "tableIndex" &&
                                p.dataIndex !== "id" && p.dataIndex !== "packetOfAictia" && (
                                  <Option key={index} value={p.dataIndex}>
                                    {p.title}
                                  </Option>
                                )
                              );
                            })}
                          </Select>
                        </div>
                      </Col>
                      <Col md={3} sm={12} xs={24} lg={3} xl={8}>
                          <Button className="file-icon">
                            <img src={excel} />
                          </Button>
                      </Col>
                      <Col md={3} sm={12} xs={24} lg={3} xl={8}>
                        {
                          role === 'Admin' && (
                            <div>
                              <Link
                                to={{
                                  pathname: `register/create`,
                                  state: { locales: null },
                                }}
                              >
                                <Button type="primary"><PlusOutlined /></Button>
                              </Link>
                            </div>
                          )
                        }
                      </Col>
                    </div> */}

                  </Row>
                </div>
              </Form>
            </div>
            <Form className="register-head head-icons" >
              <div className="register">
                <label className="labelPlace">
                  {" "}
                  <SettingOutlined />
                </label>

                <Select
                  dropdownMatchSelectWidth={false}
                  showSearch={false}
                  mode="multiple"
                  onChange={(e) => columnsData(e)}
                >
                  {individualColumns.map((p, index) => {
                    return (
                      p.dataIndex !== "tableIndex" &&
                      p.dataIndex !== "id" && p.dataIndex !== "packetOfAictia" && (
                        <Option key={index} value={p.dataIndex}>
                          {p.title}
                        </Option>
                      )
                    );
                  })}
                </Select>
              </div>

              <div>
                <Button className="file-icon">
                  <img src={excel} />
                </Button>
              </div>
              {
                role === 'Admin' && (
                  <div>
                    <Link
                      to={{
                        pathname: `register/create`,
                        state: { locales: null },
                      }}
                    >
                      <Button type="primary"><PlusOutlined /></Button>
                    </Link>
                  </div>
                )
              }
            </Form>

          </div>
        </Col>
        <>
          {spin ? (
            <Col xs={24}>
              <div className="flex animated fadeInUp bg-white all-center p-2">
                <Spin size={"large"} />
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <Table
                size="small"
                className="bg-white animated fadeIn"
                columns={trigger === 0 ? individualColumns : columns}
                dataSource={convertColumns(individualPostList, cols)}
                pagination={{
                  pageSize: pageSize,
                  current: page,
                  total: count,
                  locale: { items_per_page: "/ səhifələnmə" },
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                    sessionStorage.setItem('currentPage', page);
                  },
                }}
              />
            </Col>
          )}
        </>
      </Row>
    </div>
  );
}

export default connect(null, { notify })(IndividualMembers);

