import React, { useState, useEffect } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import { Form, Input, Checkbox } from 'antd';
import { logInUser } from "./../../../redux/actions/index";
import Images from "../../../assets/img/Images/Images";
import Buta from '../../../assets/img/buta.svg';
import logoLogin from '../../../assets/img/blue-logo-main.svg';
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { BsEye } from 'react-icons/bs'
import { BsEyeSlash } from 'react-icons/bs'
import { BsYoutube } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa';
import { BsTwitter } from 'react-icons/bs';
import { TfiLinkedin } from "react-icons/tfi";
import { FaWikipediaW } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import './style/login.scss';


const Login = (props) => {
  const { t } = useTranslation();
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [seePassword, setSeePassword] = useState(true);
  useEffect(() => {
    if (props.message.trim().length !== 0) {
      message.warning(props.message);
    }
  }, [props.message, props.notify]);
  const hidePass = () => {
    setSeePassword(!seePassword)
  }
  const logUserIn = async (e) => {
    e.preventDefault();

    await props.logInUser(Username, Password);
  };

  const [form] = Form.useForm();
  return (
    <div className='flex dir-column align-items-center justify-content-center login-footer'>
      <div className='flex flex-align-center  flex-around  ' style={{ width: '100%' }}>
        <div>
          <img src={Buta} alt="" style={{height:'640px'}} />
        </div>
        <div className='bg-white login-card'>
          <div className="mb-4 md:mb-3">
            <img src={logoLogin} alt="" />
          </div>
          <>
            <div>
              <Form layout={'vertical'}
                form={form}>
                <div className='flex dir-column gap-6 flex-between '>
                  <div className='up'>
                    <Form.Item >
                      <label>{t('username')}</label>
                      <Input
                        value={Username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text" />

                    </Form.Item>
                  </div>
                  <div className='up'>

                    <Form.Item >
                      <label>{t('password')}</label>
                      <div>
                        <Input onChange={(e) => setPassword(e.target.value)} type={seePassword ? 'password' : 'text'} />
                        {
                          seePassword
                            ?
                            <div className='eye' onClick={hidePass}><BsEyeSlash /></div>
                            :
                            <div className='eye' onClick={hidePass}><BsEye /></div>
                        }
                      </div>
                      {/* <Input.Password
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"/> */}
                    </Form.Item>
                    {/* <Form.Item  name="remember" >
                  <Checkbox checked={true}>{t('remember')}</Checkbox>
                </Form.Item> */}
                  </div>
                </div>
                <div className="submit flex flex-column gap-2">
                  <Button onClick={logUserIn} >{t('login')}</Button>
                </div>
              </Form>
            </div>
          </>

        </div>
      </div>
      <div className='main-footer'>
        <footer className='flex flex-align-center  flex-around  footer'>
          <a className="text-sm text-white footer-text" href="https://aictia.org/" target="_blank"> {new Date().getFullYear()} © AICTIA. Copyright reserved. </a>
          <div className="flex justify-content-center gap-4 social-medias">
            {/* <a target="_blank" href='https://www.facebook.com/azictiassocia/' rel="noreferrer noopener" className='text-white cursor-pointer'><BsYoutube className='text-white cursor-pointer'/></a> */}
            <a target="_blank" href='https://www.facebook.com/azictiassocia/' rel="noreferrer noopener" className='icon cursor-pointer'><FaFacebookF className='text-white cursor-pointer' /></a>
            {/* <a target="_blank" href='https://www.facebook.com/azictiassocia/' rel="noreferrer noopener" className='text-white cursor-pointer'><BsTwitter className='text-white cursor-pointer'/></a> */}
            <a target="_blank" href='https://az.linkedin.com/company/aictia' rel="noreferrer noopener" className='icon cursor-pointer'><TfiLinkedin className='text-white cursor-pointer' /></a>
            <a target="_blank" href='https://az.wikipedia.org/wiki/Az%C9%99rbaycan_%C4%B0nformasiya_v%C9%99_Kommunikasiya_Texnologiyalar%C4%B1_S%C9%99nayesi_Assosiasiyas%C4%B1' rel="noreferrer noopener" className='icon cursor-pointer'><FaWikipediaW/></a>
            <a target="_blank" href='https://aictia.org/' rel="noreferrer noopener" className='icon cursor-pointer'><FaGlobe  /></a>
          </div>
          <a target="_blank" href={'https://vac.az/'} rel="noreferrer noopener" className=' text-white text-sm footer-text'>
            Developed with by Virtual Azerbaijan
          </a>
        </footer>
      </div>
    </div>

    // <div className="h-100vh flex  login">
    //   <div>
    //     <img src={Buta} alt=""/>
    //   </div>
    //   <div className="position-relative-login">
    //    <div>
    //       <img src={logoLogin} alt=""/>
    //    </div>
    //     <div className="customForm">
    //       <Form
    //           layout={'vertical'}
    //           form={form}
    //       >
    //         <Form.Item label={t('email')}>
    //           <Input
    //               value={Username}
    //               onChange={(e) => setUsername(e.target.value)}
    //               type="text"
    //               placeholder={t('email')} />
    //         </Form.Item>
    //         <Form.Item label={t('password')}>
    //           <Input.Password
    //               value={Password}
    //               onChange={(e) => setPassword(e.target.value)}
    //               type="password"
    //               placeholder={t('password')} />
    //         </Form.Item>
    //         <Form.Item  name="remember" >
    //           <Checkbox checked={true}>{t('remember')}</Checkbox>
    //         </Form.Item>
    //         <Form.Item>
    //           <div className='flex all-center'>
    //             <button  onClick={logUserIn}  className=' entry-button '>{t('login')}</button>
    //           </div>
    //         </Form.Item>
    //       </Form>
    //     </div>
    //     <div className="lists">
    //       <img src={Images.list1} alt=""/>
    //       <img src={Images.list2} alt=""/>
    //     </div>
    //   </div>
    // </div>
  );
};
const mapStateToProps = ({ user }) => {
  return {
    loggedIn: user.isLoggedIn,
    message: user.message,
    notify: user.notify,
  };
};

export default connect(mapStateToProps, { logInUser })(Login);
